import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgReset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M1.667 9.333h4a.63.63 0 0 1 .468.198.63.63 0 0 1 .198.469.647.647 0 0 1-.198.474.638.638 0 0 1-.468.193h-2.25l1.807 1.765.005.005a5.15 5.15 0 0 0 1.766 1.172C7.63 13.87 8.299 14 9 14a5.237 5.237 0 0 0 2.98-.917 4.956 4.956 0 0 0 1.358-1.328 5.361 5.361 0 0 0 .693-1.312.632.632 0 0 1 .344-.375.641.641 0 0 1 .505-.031.632.632 0 0 1 .375.343c.08.163.09.33.031.5a5.908 5.908 0 0 1-.635 1.323c-.278.438-.59.83-.938 1.177a6.594 6.594 0 0 1-2.203 1.464 6.626 6.626 0 0 1-2.51.49 6.652 6.652 0 0 1-4.714-1.954L2.333 11.47V14a.63.63 0 0 1-.198.469.63.63 0 0 1-.468.198.647.647 0 0 1-.474-.198A.638.638 0 0 1 1 14v-4c0-.184.064-.34.193-.469a.647.647 0 0 1 .474-.198ZM6.49 2.49A6.65 6.65 0 0 1 9 2a6.65 6.65 0 0 1 4.713 1.953l.011.01 1.943 1.902V3.333c0-.184.064-.34.192-.468a.646.646 0 0 1 .474-.198.63.63 0 0 1 .47.198.63.63 0 0 1 .197.468v4a.647.647 0 0 1-.198.474.638.638 0 0 1-.469.193h-4a.655.655 0 0 1-.666-.667c0-.184.064-.34.192-.468a.646.646 0 0 1 .474-.198h2.25l-1.812-1.771a5.27 5.27 0 0 0-1.735-1.162A5.272 5.272 0 0 0 9 3.334c-.701 0-1.37.13-2.005.39a5.15 5.15 0 0 0-1.766 1.172c-.208.184-.437.465-.687.844a5.158 5.158 0 0 0-.573 1.15.651.651 0 0 1-.344.38.655.655 0 0 1-.505.027.654.654 0 0 1-.406-.844c.145-.444.357-.885.635-1.323.278-.437.59-.83.937-1.177A6.594 6.594 0 0 1 6.49 2.49Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgReset;
