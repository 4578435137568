import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  RefCallback,
  RefObject,
} from 'react';
import { useDrag, useDragLayer } from 'react-dnd';
import { createStyles, makeStyles, Paper } from '../ui/mui';
import { DatasetSetup, Node as NodeData } from '@tensorleap/api-client';

import { NodeDescriptor } from './interfaces';
import { addPositions, Position } from '../core/position';
import clsx from 'clsx';
import { NodeInputSocket, NodeOutputSocket } from './NodeSockets';
import {
  ConnectionDropResult,
  NodeRepositionDragData,
  NODE_REPOSITION_DRAG,
  ConnectionWithType,
} from './interfaces/drag-and-drop';
import { Connection } from './interfaces/Connection';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useMergedRefs } from '../core/mergeRefs';
import { Alert } from '../ui/icons';
import { PanZoomParams } from './PanZoom';
import { useContextMenuPopoverState } from '../core/useContextMenuPopoverState';
import { NodeContextMenu } from './NodeContextMenu';
import { getInputsData, getOutputData, shouldShowShape } from './utils';
import { BlockType } from './descriptor/types';
import { useDescriptors } from './descriptor/utils';
import { lastExistedProp } from '../core/array-helper';
import { DisplayNodeName } from './DysplayNodeName';
import { NetworkNodeUiRefs } from './hooks';
import { NodePresentationState } from './graph-calculation/contract';
import { isGroundTruthNode, isInputNode } from './graph-calculation/utils';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

interface StyleProps {
  blockType: BlockType;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    node: ({ blockType }: StyleProps) => ({
      backgroundColor: theme.palette.networkEditor[blockType].main,
      '&:hover': {
        backgroundColor: theme.palette.networkEditor[blockType].hover.main,
      },
      '&:hover > $title': {
        backgroundColor: theme.palette.networkEditor[blockType].hover.title,
      },
    }),
    title: ({ blockType }: StyleProps) => ({
      boxShadow: `0 4px 4px ${theme.palette.networkEditor.boxShadow}`,
      backgroundColor: theme.palette.networkEditor[blockType].title,
    }),
  })
);

export interface NodeParams {
  node: NodeData;
  panZoomParamsRef: RefObject<PanZoomParams>;
  isSelected: boolean;
  inputConnections?: Map<string, ConnectionWithType>;
  onPositionChanged?: (nodeId: string, offset: Position) => void;
  onSelect?: (nodeId: string) => void;
  onNewConnection?: (data: ConnectionDropResult) => void;
  onConnectionRemoved?: (
    connection: Connection,
    isDynamicInput: boolean
  ) => void;

  componentDescriptor?: NodeDescriptor;

  nodeRefs: React.MutableRefObject<Map<string, NetworkNodeUiRefs>>;
  nodeShape?: NodePresentationState;
  currentDatasetSetup?: DatasetSetup;
  datasetSetup?: DatasetSetup;
  notifyIsLoadingNodes: () => void;
}
export const Node = React.memo<NodeParams>(
  ({
    node,
    panZoomParamsRef,
    isSelected,
    onSelect,
    inputConnections,
    onPositionChanged,
    onNewConnection,
    onConnectionRemoved,

    componentDescriptor,

    nodeRefs,
    nodeShape,
    currentDatasetSetup,
    datasetSetup,
    notifyIsLoadingNodes,
  }) => {
    const descriptors = useDescriptors(node);
    const styleProps = useMemo<StyleProps>(
      () => ({
        blockType: lastExistedProp(descriptors, 'colorTheme') ?? 'layer',
      }),
      [descriptors]
    );
    const classes = useStyles(styleProps);

    const domTarget = useRef<HTMLDivElement>();
    const inputRefs = useRef<NetworkNodeUiRefs['inputRefs']>(new Map());
    const outputRefs = useRef<NetworkNodeUiRefs['outputRefs']>(new Map());

    const contextMenuProps = useContextMenuPopoverState();
    const { openContextMenu } = contextMenuProps;

    useEffect(() => {
      notifyIsLoadingNodes();
      const currentNodeRefs = nodeRefs.current;

      currentNodeRefs.set(node.id, {
        nodeRef: domTarget.current,
        inputRefs: inputRefs.current,
        outputRefs: outputRefs.current,
      });
      return () => {
        currentNodeRefs.delete(node.id);
      };
    }, [nodeRefs, node.id, notifyIsLoadingNodes]);

    const dragPosition = useDragLayer<Position | null>((monitor) => {
      if (
        !monitor.isDragging() ||
        monitor.getItemType() !== NODE_REPOSITION_DRAG
      ) {
        return null;
      }

      const { nodeId, zoom } = monitor.getItem() as NodeRepositionDragData;
      if (nodeId !== node.id) {
        return null;
      }

      const offset = monitor.getDifferenceFromInitialOffset() || { x: 0, y: 0 };
      return addPositions(node.position as Position, [
        offset.x / zoom,
        offset.y / zoom,
      ]);
    });

    const position = dragPosition || node.position;

    const style: React.CSSProperties = {
      transform: `translate(${position[0]}px, ${position[1]}px)`,
    };

    const [, dragRef, preview] = useDrag(
      () => ({
        type: NODE_REPOSITION_DRAG,
        item: (): NodeRepositionDragData => ({
          nodeId: node.id,
          zoom: panZoomParamsRef.current?.zoom ?? 1,
        }),
        end: ({ nodeId, zoom }, monitor) => {
          const offset = monitor.getDifferenceFromInitialOffset();
          if (!offset) return;

          onPositionChanged?.(
            nodeId,
            addPositions(node.position as Position, [
              offset.x / zoom,
              offset.y / zoom,
            ])
          );
        },
      }),
      [node]
    );

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    const inputs = useMemo(() => {
      if (!componentDescriptor && node.name !== 'Model') {
        return null;
      }

      const { isDynamicInput, namePattern, inputsData } = getInputsData(
        node,
        componentDescriptor,
        node.data.custom_input_keys
      );

      return inputsData.map(({ name, approval_connection }, idx) => (
        <NodeInputSocket
          ref={(elem) =>
            elem
              ? inputRefs.current.set(name, elem)
              : inputRefs.current.delete(name)
          }
          key={name}
          allowedConnectionTypes={approval_connection}
          connectionInfo={inputConnections?.get(name)}
          nodeId={node.id}
          name={name}
          isDynamicInput={isDynamicInput}
          namePattern={
            isDynamicInput && idx === inputsData.length - 1 ? '' : namePattern
          }
          onNewConnection={onNewConnection}
          onConnectionRemoved={onConnectionRemoved}
        />
      ));
    }, [
      componentDescriptor,
      node,
      inputConnections,
      onNewConnection,
      onConnectionRemoved,
    ]);

    const nodeLinkageState = useMemo(
      () =>
        isInputNode(node) && datasetSetup
          ? {
              shape:
                datasetSetup.inputs.find(
                  ({ name }) => name === node.data['output_name']
                )?.shape || [],
              receptiveFields: null,
            }
          : isGroundTruthNode(node) && datasetSetup
          ? {
              shape:
                datasetSetup.outputs.find(
                  ({ name }) => name === node.data['output_name']
                )?.shape || [],
              receptiveFields: null,
            }
          : nodeShape,
      [datasetSetup, node, nodeShape]
    );

    const outputs = useMemo(() => {
      const output_names = getOutputData(
        node,
        componentDescriptor,
        currentDatasetSetup
      );

      return output_names?.map(({ name, type }) => (
        <NodeOutputSocket
          ref={(elem) =>
            elem
              ? outputRefs.current.set(name, elem)
              : outputRefs.current.delete(name)
          }
          key={name}
          type={type}
          nodeId={node.id}
          name={name}
          onNewConnection={onNewConnection}
          title={
            <OutputTitle
              {...{
                node,
                nodeState: nodeLinkageState,
                name,
                type,
              }}
            />
          }
        />
      ));
    }, [
      node,
      componentDescriptor,
      onNewConnection,
      nodeLinkageState,
      currentDatasetSetup,
    ]);

    const handleOnClick = useCallback<React.MouseEventHandler<HTMLElement>>(
      (ev) => {
        if (isSelected) return;

        ev.preventDefault();
        ev.stopPropagation();
        onSelect?.(node.id);
      },
      [node.id, isSelected, onSelect]
    );

    const mergedRef = useMergedRefs(
      domTarget,
      dragRef as RefCallback<HTMLDivElement>
    );
    return (
      <>
        <Paper
          ref={mergedRef}
          className={clsx(
            'absolute min-w-[200px] min-h-[128px] rounded-lg border-2 border-solid border-transparent will-change-transform',
            classes.node,
            isSelected && 'border-2 border-solid border-block-50'
          )}
          style={style}
          onClick={handleOnClick}
          onContextMenu={openContextMenu}
        >
          <div
            className={clsx(
              'flex flex-row items-center p-2 h-11 rounded-t-lg',
              classes.title
            )}
            id={TOUR_SELECTORS_ENUM.NETWORK_NODE_ID}
          >
            <h6 className="font-semibold text-lg tracking-normal ml-1">
              <DisplayNodeName node={node} />
            </h6>
            {nodeShape?.error && (
              <div className="flex-1 flex justify-end">
                <Alert className="text-error-500" />
              </div>
            )}
          </div>

          {outputs}
          {inputs}
        </Paper>
        <NodeContextMenu {...contextMenuProps} node={node} />
      </>
    );
  }
);

Node.displayName = 'Node';

function OutputTitle({
  node,
  nodeState,
  name,
  type: _,
}: {
  node: NodeData;
  nodeState?: NodePresentationState;
  name: string;
  type: string;
}): JSX.Element {
  const shape = nodeState?.shape?.join();
  return (
    <div className="flex flex-col items-end mt-2 space-y-2 ">
      <span>{name}</span>
      {shouldShowShape(node) && shape ? (
        <span className="text-xs text-white/80 "> {shape} </span>
      ) : (
        <span className="text-xs text-white/60 italic">Unknown shape</span>
      )}
    </div>
  );
}
