import { FilterOperatorType, NumberOrString } from '@tensorleap/api-client';
import { useScatterData } from '../ScatterDataContext';
import { LabelsScatterLegend } from './LabelsScatterLegend';
import { RangeScatterLegend } from './RangeScatterLegend';
import { ShapesLegend } from './ShapeLegend';
import {
  ClusterVisualizationFilter,
  EQVisualizationFilter,
  INVisualizationFilter,
  VisualizationFilter,
} from '../../core/types/filters';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import { trimStorageUrlProject } from '../../core/useProjectStorage';

export function ScatterLegend(): JSX.Element {
  const {
    clusterBlobPaths,
    settings: { dotColor, sizeOrShape },
    isCanDisplayFieldAsCategorical,
  } = useScatterData();

  const showSizeOrShape =
    sizeOrShape !== undefined && isCanDisplayFieldAsCategorical(sizeOrShape);
  const showRangeColorLegend =
    dotColor !== undefined && !isCanDisplayFieldAsCategorical(dotColor);
  const showLabelsColorLegend =
    dotColor !== undefined && isCanDisplayFieldAsCategorical(dotColor);

  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const addFilterOnClick = (
    key: string,
    label: NumberOrString | NumberOrString[]
  ) => {
    if (!key) return;

    let filter: VisualizationFilter;

    const isCluster = clusterBlobPaths && clusterBlobPaths[key];

    if (isCluster && !Array.isArray(label)) {
      const url = clusterBlobPaths[key][label];
      const mappedUrl = trimStorageUrlProject(url); // fixing wrong path

      const clusterFilter: ClusterVisualizationFilter = {
        field: key,
        operator: FilterOperatorType.Cluster,
        value: {
          url: mappedUrl,
          state: 'ready',
        },
        pin: false,
      };
      filter = clusterFilter;
    } else if (key.startsWith('cluster') || isCluster) {
      console.warn(`Cluster filter is not supported for this key: ${key}`);
      return;
    } else {
      let field = key;
      if (!field.endsWith('.keyword')) {
        field += '.keyword';
      }
      if (Array.isArray(label)) {
        const inFilter: INVisualizationFilter = {
          field,
          operator: FilterOperatorType.In,
          value: label as string[],
          pin: false,
        };
        filter = inFilter;
      } else {
        const eqFilter: EQVisualizationFilter = {
          field,
          operator: FilterOperatorType.Equal,
          value: label,
          pin: false,
        };
        filter = eqFilter;
      }
    }

    updateDashletFilters([...dashletFilters, filter]);
  };

  return (
    <div className="flex flex-col h-full gap-2 overflow-y-auto overflow-x-hidden">
      {showRangeColorLegend && <RangeScatterLegend />}
      {showLabelsColorLegend && (
        <LabelsScatterLegend onLegendClick={addFilterOnClick} />
      )}
      {showSizeOrShape && <ShapesLegend onLegendClick={addFilterOnClick} />}
    </div>
  );
}
