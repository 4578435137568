import { TooltipProps } from '@material-ui/core';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';

export type InteractiveTooltipProps = {
  content: TooltipProps['title'];
  children: TooltipProps['children'];
  open: boolean;
  className?: string;
  contentClass?: string;
  arrowClass?: string;
  onClose: () => void;
};

export function InteractiveTooltip({
  children,
  content: popup,
  open,
  className,
  contentClass = 'bg-gray-800 border-gray-700 border-2 shadow-4c',
  arrowClass = 'text-gray-700',
  onClose,
}: InteractiveTooltipProps) {
  return open ? (
    <InteractiveTooltipLogic
      content={popup}
      open={open}
      className={className}
      contentClass={contentClass}
      arrowClass={arrowClass}
      onClose={onClose}
    >
      {children}
    </InteractiveTooltipLogic>
  ) : (
    <>{children}</>
  );
}

function InteractiveTooltipLogic({
  children,
  content: popup,
  open,
  className,
  contentClass = 'bg-gray-800 border-gray-700 border-2 shadow-4c',
  arrowClass = 'text-gray-700',
  onClose,
}: InteractiveTooltipProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={className}>
        <Tooltip
          arrow
          placement="bottom"
          classes={{
            tooltip: clsx('top-2 m-0 max-w-[500px]', contentClass),
            arrow: arrowClass,
          }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          title={popup}
        >
          {children}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
