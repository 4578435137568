import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ExportModelTypeEnum,
  ExportedModelData,
  SessionWeightData,
  SlimVersion,
} from '@tensorleap/api-client';
import api from '../core/api-client';
import { Dialog, Button, Divider, IconButton } from '../ui/mui';
import { XClose, FwArrowIcon, CloudDlIcon } from '../ui/icons';
import { getSourceNetworkName } from './helper-functions';
import { useVersionControl } from '../core/VersionControlContext';
import { first, max } from 'lodash';
import { SelectEpochLineChart } from '../ui/SelectEpochLineChart';
import { Select } from '../ui/atoms/Select';
import { ModelFields } from '../ui/model-list/types';
import { Table } from '../ui/model-list/table/Table';
import clsx from 'clsx';
import { DIALOG_HEIGHT_CLASSES } from './common';
import { useMapProjectStorageUrl } from '../core/useProjectStorage';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useExportedSessionRuns } from '../core/data-fetching/getExportedSessionRuns';
import { usePushNotifications } from '../core/PushNotificationsContext';
import { isExportModelMsg } from '../core/websocket-message-types';
import { TextDisplayBox } from '../ui/atoms/TextDisplayBox';

export interface ExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  sessionWeights: SessionWeightData[];
  defaultModelType?: ExportModelTypeEnum;
}

const MODEL_TYPE_SELECT_OPTIONS = Object.values(ExportModelTypeEnum);

export function ExportDialog({
  isOpen,
  onClose,
  sessionWeights,
  defaultModelType,
}: ExportDialogProps): JSX.Element {
  const { lastServerMessage } = usePushNotifications();
  const { sessionToVersionsMap, versions } = useVersionControl();
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const sessionId = useMemo(() => sessionWeights?.[0].sessionId, [
    sessionWeights,
  ]);
  const {
    exportedModels,
    refetch: refetchExportedModels,
  } = useExportedSessionRuns(projectId, sessionId);

  useEffect(() => {
    if (isExportModelMsg(lastServerMessage)) {
      refetchExportedModels();
    }
  }, [lastServerMessage, refetchExportedModels]);

  const version = sessionToVersionsMap.get(
    sessionWeights?.[0].sessionId
  ) as SlimVersion;

  const uniqueEpochOptions = useMemo(
    () =>
      Array.from(new Set(sessionWeights.map(({ epoch }) => epoch))).sort(
        (a, b) => b - a
      ),
    [sessionWeights]
  );
  const [fileType, setFileType] = useState<string | undefined>(
    defaultModelType || ExportModelTypeEnum.JsonTf2
  );

  const [selectedEpoch, setSelectedEpoch] = useState<number | undefined>(
    max(uniqueEpochOptions)
  );

  const handleExportModel = useCallback(async () => {
    const fromEpoch = selectedEpoch ?? max(uniqueEpochOptions);
    const sessionWeight = sessionWeights.find(
      ({ epoch }) => epoch === fromEpoch
    );

    if (fromEpoch === undefined || sessionWeight?.cid === undefined) {
      return;
    }
    await api.addExportModelJob({
      sessionWeightId: sessionWeight.cid,
      projectId,
      exportModelType: fileType as ExportModelTypeEnum,
      title: fileType as string,
      pruneModel: false,
    });
    await refetchExportedModels();
  }, [
    selectedEpoch,
    uniqueEpochOptions,
    sessionWeights,
    projectId,
    fileType,
    refetchExportedModels,
  ]);

  const session = useMemo(
    () =>
      versions
        .flatMap(({ sessions }) => sessions)
        .find(({ cid }) => cid === sessionId),
    [sessionId, versions]
  );
  const sessionRunId = useMemo(() => {
    const sessionWeightId = sessionWeights.find(
      ({ epoch }) => selectedEpoch === epoch
    )?.cid;

    return session?.sessionRuns?.find((sessionRun) =>
      sessionRun.weightAssets.some(
        (weight) => weight.sessionWeightId === sessionWeightId
      )
    )?.cid;
  }, [selectedEpoch, session?.sessionRuns, sessionWeights]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <div className={clsx('flex flex-col', DIALOG_HEIGHT_CLASSES)}>
        <div className="flex flex-row h-16 px-8 justify-between items-center bg-gray-850">
          <h5 className="font-normal text-2xl leading-snug">EXPORT MODEL</h5>
          <IconButton onClick={onClose}>
            <XClose />
          </IconButton>
        </div>

        <div className="flex-1 flex overflow-hidden flex-row">
          <div className="w-[1150px] flex-1 flex flex-row py-10 bg-gray-900">
            <div className="flex-1 flex flex-col gap-4 px-10 w-full overflow-hidden">
              <TextDisplayBox
                header={'model name'}
                text={session?.modelName || ''}
              />
              <TextDisplayBox
                header={'REVISION NAME'}
                text={getSourceNetworkName(version)}
              />
              <div className="flex gap-4">
                <Select
                  label="FILE TYPE"
                  value={fileType}
                  className="flex-1 bg-gray-850"
                  onChange={setFileType}
                  options={MODEL_TYPE_SELECT_OPTIONS}
                />
                {sessionWeights.length > 0 && (
                  <Select
                    value={
                      selectedEpoch !== undefined
                        ? selectedEpoch
                        : first(uniqueEpochOptions)
                    }
                    options={uniqueEpochOptions}
                    onChange={(value) => setSelectedEpoch(Number(value))}
                    label="FROM EPOCH"
                    optionToLabel={(o) => o.toString()}
                    className="bg-gray-850"
                  />
                )}
              </div>

              {!!sessionRunId && (
                <SelectEpochLineChart
                  sessionRunId={sessionRunId}
                  projectId={projectId}
                />
              )}
            </div>

            <Divider orientation="vertical" />
            <div className="flex-1 flex flex-col px-4 overflow-hidden">
              <h6 className="font-medium text-xl leading-normal">
                Previously Compiled Versions of this Model
              </h6>
              <Table
                fields={tableColumns}
                data={exportedModels}
                className="max-h-96 mb-0"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row h-20 px-8 justify-end items-center bg-black">
          <Button
            variant="outlined"
            color="primary"
            className="mr-6"
            onClick={onClose}
          >
            <h6 className="font-medium text-xl leading-normal">CANCEL</h6>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleExportModel}
            className="bg-primary-500"
          >
            <h6 className="font-medium text-xl leading-normal">COMPILE</h6>
            <FwArrowIcon className="ml-2" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const tableColumns: ModelFields<ExportedModelData> = [
  {
    accessorKey: 'title',
    label: 'FILE TYPE',
  },
  {
    accessorKey: 'status',
    label: 'STATUS',
    format: (status) => status.toString(),
  },
  {
    accessorKey: 'createdAt',
    label: 'CREATED AT',
    format: (createdAt) => new Date(createdAt).toLocaleString() || '',
  },
  {
    label: '',
    format: ({ cid, blob }: ExportedModelData) => (
      <DownloadModel exportedSessionRunId={cid} blob={blob} />
    ),
  },
];

interface DownloadSessionProps {
  exportedSessionRunId?: string;
  blob: string;
}

function DownloadModel({
  exportedSessionRunId,
  blob,
}: DownloadSessionProps): JSX.Element {
  const { mapUrl } = useMapProjectStorageUrl();

  const handleDownloadClick = useCallback(async () => {
    const fullBlobUrl = mapUrl(blob);
    downloadUrl(fullBlobUrl);
  }, [mapUrl, blob]);
  return (
    <>
      {exportedSessionRunId && blob && (
        <IconButton size="small" onClick={handleDownloadClick}>
          <CloudDlIcon />
        </IconButton>
      )}
    </>
  );
}

function downloadUrl(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
