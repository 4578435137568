import { useCallback } from 'react';
import { Visible, NonVisible } from '../../ui/icons';
import { Chip } from '../../ui/atoms/Chip';
import { SelectedSessionRun } from '../../ui/molecules/useModelFilter';
import { ActionResult } from '../../core/types';
import { Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../../core/formatters/string-formatting';
import { CHIP_COLOR_TEMPLATES } from './colorTemplates';
import { useVersionControl } from '../../core/VersionControlContext';
import { sessionRunTooltip } from '../../version-control/helper';
import { EpochSelector } from '../../dashboard/dashlet/common/EpochSelector';

export type ModelChipProps = SelectedSessionRun & {
  remove?: (ids: string[]) => ActionResult;
  toggleVisibility?: (id: string) => ActionResult;
  hideInvisibilityIcon?: boolean;
  allowSwitchEpochs?: boolean;
};

export function ModelChip({
  name,
  id,
  visible,
  remove,
  toggleVisibility,
  hideInvisibilityIcon,
  modelUniqueKey,
  epochsState,
  allowSwitchEpochs = false,
}: ModelChipProps) {
  const { selectedSessionRunMap } = useVersionControl();
  const sessionRunData = selectedSessionRunMap.get(id);
  const tooltip = sessionRunData
    ? sessionRunTooltip(
        sessionRunData,
        sessionRunData?.epochsState?.selectedEpoch
      )
    : name;

  const removeWithId = useCallback(() => {
    remove?.([id]);
  }, [id, remove]);

  const toggleVisibilityWithId = useCallback(() => {
    toggleVisibility?.(id);
  }, [id, toggleVisibility]);

  const colorfulChipStyle = CHIP_COLOR_TEMPLATES[modelUniqueKey];

  return (
    <Chip
      borderClassName={
        visible ? colorfulChipStyle : 'border-solid border-gray-700 bg-gray-800'
      }
      onRemove={remove ? removeWithId : undefined}
      key={id}
    >
      <Tooltip arrow title={tooltip}>
        <div className="flex flex-row items-center">
          <span>
            {truncateLongtail({
              value: name,
              startSubsetLength: 8,
              endSubsetLength: 7,
            })}
          </span>
          {epochsState && (
            <EpochSelector
              epochs={epochsState.epochs}
              value={epochsState.selectedEpoch}
              onSelect={epochsState.selectEpoch}
              allowSwitchEpochs={allowSwitchEpochs}
            />
          )}
        </div>
      </Tooltip>
      {!hideInvisibilityIcon && toggleVisibility && (
        <VisibleOrNonVisibleChipIcon
          visible={visible}
          toggle={toggleVisibilityWithId}
        />
      )}
    </Chip>
  );
}

export function VisibleOrNonVisibleChipIcon({
  toggle,
  visible,
}: {
  toggle: () => void;
  visible: boolean;
}) {
  const VisibleOrNonVisibleIcon = visible ? Visible : NonVisible;

  return (
    <VisibleOrNonVisibleIcon
      onClick={toggle}
      className="ml-[6px] text-lg hover:bg-white/10 cursor-pointer rounded"
    />
  );
}
