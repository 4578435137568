import { IconButton } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';
import { DownSmallIcon, UpSmallIcon } from '../../ui/icons';
import { FetchSimilarButton } from './FetchSimilarButton';
import { Button } from '../../ui/atoms/Button';
import { FetchSimilarDrawer } from '../dashlet/VisualizationDisplay/FetchSimilarDrawer';
import {
  FilterOperatorType,
  HttpMethods,
  SampleIdentity,
} from '@tensorleap/api-client';
import { SampleAnalysisDrawer } from '../dashlet/VisualizationDisplay/SampleAnalysisDrawer';
import { ClusterData } from '@tensorleap/engine-contract';
import { calcHash } from '../../core/calc-hash';
import { groupBy, mapValues } from 'lodash';
import { useMapProjectStorageUrl } from '../../core/useProjectStorage';
import { isFileExists, uploadJsonFile } from '../../core/file-helper';
import { ClusterVisualizationFilter } from '../../core/types/filters';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import api, { addBasePathToURL } from '../../core/api-client';
export interface SamplesActionsDrawerProps {
  selectedSampleIdentities: SampleIdentity[];
}

export function SamplesActionsDrawer({
  selectedSampleIdentities,
}: SamplesActionsDrawerProps): JSX.Element {
  const { mapUrl } = useMapProjectStorageUrl();
  const [isOpen, setIsOpen] = useState(true);
  const [isFetchSimilarDrawerOpen, setIsFetchSimilarDrawerOpen] = useState(
    false
  );
  const [isSampleAnalysisDrawerOpen, setIsSampleAnalysisDrawerOpen] = useState(
    false
  );

  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const filterSelected = useCallback(async () => {
    const hash = calcHash(selectedSampleIdentities);
    const sampleIndex: Record<string, number[]> = mapValues(
      groupBy(selectedSampleIdentities, (sample) => sample.state),
      (samples) => samples.map((sample) => sample.index)
    );
    const clusterData: ClusterData = {
      is_auto_generated: true,
      cluster_name: hash,
      cluster_value: 'states',
      samples_index: sampleIndex,
      samples_metadata: (null as unknown) as undefined,
    };
    const projectPath = `clusters/${hash}.json`;
    const filePath = mapUrl(projectPath);
    const isExists = await isFileExists(filePath);
    if (!isExists) {
      const blobPath = filePath.slice(filePath.indexOf('organizations/'));
      const { url } = await api.getSignedUrl({
        expired: 24 * 60 * 60,
        method: HttpMethods.Put,
        fileName: blobPath,
      });
      const urlWithBasePath = addBasePathToURL(url);
      await uploadJsonFile(urlWithBasePath, clusterData);
    }
    const filter: ClusterVisualizationFilter = {
      field: FilterOperatorType.Cluster,
      operator: FilterOperatorType.Cluster,
      value: {
        url: projectPath,
        state: 'ready',
      },
    };
    updateDashletFilters([...dashletFilters, filter]);
  }, [mapUrl, selectedSampleIdentities, updateDashletFilters, dashletFilters]);

  const handleOpenDrawer = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeFetchSimilarDrawer = useCallback(() => {
    setIsFetchSimilarDrawerOpen(false);
  }, []);

  const openFetchSimilarDrawer = useCallback(() => {
    setIsFetchSimilarDrawerOpen(true);
  }, []);

  const openSampleAnalysisDrawer = useCallback(() => {
    setIsSampleAnalysisDrawerOpen(true);
  }, []);

  const closeSampleAnalysisDrawer = useCallback(() => {
    setIsSampleAnalysisDrawerOpen(false);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsOpen(false);
    closeFetchSimilarDrawer();
    closeSampleAnalysisDrawer();
  }, [closeFetchSimilarDrawer, closeSampleAnalysisDrawer]);

  return (
    <div className="flex flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg">
      <div className="flex flex-row h-[6px] gap-1">
        <span className="bg-primary-700 w-full h-[6px] -mr-4 rounded-tl-md" />
        <IconButton
          onClick={isOpen ? handleCloseDrawer : handleOpenDrawer}
          className="w-2 h-2 p-[10px] bg-primary-700 font-bold -mt-2"
        >
          {isOpen ? <DownSmallIcon /> : <UpSmallIcon />}
        </IconButton>

        <span className="bg-primary-700 w-full h-[6px] -ml-4 rounded-tr-md" />
      </div>
      {isFetchSimilarDrawerOpen ? (
        <FetchSimilarDrawer closeFetchSimilarDrawer={closeFetchSimilarDrawer} />
      ) : isSampleAnalysisDrawerOpen ? (
        <SampleAnalysisDrawer
          closeSampleAnalysisDrawer={closeSampleAnalysisDrawer}
          selectedSampleIdentities={selectedSampleIdentities}
        />
      ) : isOpen ? (
        <div className="flex flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg">
          <div className="flex flex-wrap justify-center p-4 pt-5 gap-2 w-full">
            <FetchSimilarButton
              onClick={openFetchSimilarDrawer}
              buttonClassName="min-w-[190px] h-8"
              tourId={
                TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_EXPANDER_BUTTON_ID
              }
            />

            <Button
              variant="inverted-blue"
              onClick={openSampleAnalysisDrawer}
              className="min-w-[190px] h-8"
              tourId={
                TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_OPEN_SAMPLE_ANALYSIS_DRAWER_BUTTON_ID
              }
            >
              analyze sample
            </Button>

            <Button
              onClick={filterSelected}
              variant="inverted-blue"
              className="min-w-[190px] h-8"
              tourId={
                TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_OPEN_SAMPLE_ANALYSIS_DRAWER_BUTTON_ID
              }
            >
              filter selected
            </Button>
          </div>
        </div>
      ) : (
        <span className="h-0" />
      )}
    </div>
  );
}
