import { IconButton, Tooltip } from '@material-ui/core';
import {
  AggregationMethod,
  AnalyticsDashletType,
  OrderType,
} from '@tensorleap/api-client';
import clsx from 'clsx';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { Button } from '../../../../ui/atoms/Button';
import { Input } from '../../../../ui/atoms/Input';
import { Select } from '../../../../ui/atoms/Select';
import { Switch } from '../../../../ui/atoms/Switch';
import {
  ConfusionMatrixIcon,
  IntervalIcon,
  Plus,
  SizeIcon2,
  SortBigSmallIcon,
  XClose,
} from '../../../../ui/icons';
import { HeatmapDashletForm } from './Heatmap';
import { TableDashletForm } from './Table';
import {
  BarsIcon,
  GraphIcon,
  AreaIcon,
  TableIcon,
  HeatMapIcon,
  BagelIcon,
} from '../../../../ui/icons';
import { useController } from 'react-hook-form';
import {
  ExtractGraphParams,
  heatmapParamsUtils,
  tableParamsUtils,
  GraphParamsValidator,
  confusionMatrixParamsUtils,
  xyChartParamsUtils,
  donutParamsUtils,
} from './utils';
import {
  DashletFormProps,
  UnifyGraphParams,
  MetaDataTableEntity,
  MetricTableEntity,
  ALPHBETICAL,
  DataDistributionType,
  ChartSplit,
} from '../types';
import { ActionButton } from '../../../../ui/molecules/ActionButton';
import { OptionType } from '../../../../ui/atoms/utils/select';
import {
  dataDistributionOptions,
  orderOptions,
  OrientationType,
  orientationOptions,
  SliceSizeByType,
  SliceSizeByOptions,
} from '../../../../ui/charts/common/types';
import { ConfusionMatrixForm } from './ConfusionMatrix';
import { XYChartDashletForm } from './XYChart';
import { ComputedModelField } from '../../../../ui/model-list/model-field-types';
import { ModelFields } from '../../../../ui/model-list/types';
import { createFieldHelper } from '../../../../ui/model-list/utils';
import { Table } from '../../../../ui/model-list/table/Table';
import {
  builtOnValueChange,
  OnItemChange,
} from '../../../../ui/atoms/utils/editable-field-utils';
import { Setter } from '../../../../core/types';
import { NO_SPLIT_SELECTED } from '../ElasticVis/utils';
import { ToggleButtonGroup } from '../../../../ui/atoms/ToggleButtonGroup';
import { SelectMultiple } from '../../../../ui/atoms/SelectMultiple';
import { useGetConfusionMatrixLabels } from '../../../../core/data-fetching/getConfusionMatrixLabels';
import { DashletFields } from '../../../../core/data-fetching/dashlet-fields';

interface DashletFormHeaderProps {
  graphType: string;
  reset?: () => void;
  cancel: () => void;
}

interface DashletFormParagraphProps {
  content: string;
  className?: string;
}

export function DashletFormParagraph({
  content,
  className,
}: DashletFormParagraphProps): JSX.Element {
  return (
    <p
      className={clsx(
        'text-sm leading-relaxed uppercase text-white rounded-t-2xl py-1',
        className
      )}
    >
      {content}
    </p>
  );
}

type GraphComponentType = (
  _: DashletFormProps<UnifyGraphParams | Record<string, unknown>>
) => JSX.Element;

type GraphMeta = {
  icon: JSX.Element;
  Component: GraphComponentType;
  extractor: ExtractGraphParams;
  validator: GraphParamsValidator;
};

type ElasticGraphMeta = GraphMeta;

export interface UseGraphMetaMap {
  graphMetaMap: Record<AnalyticsDashletType, ElasticGraphMeta>;
  tabItems: {
    label: AnalyticsDashletType;
    icon: JSX.Element;
  }[];
}
export function useGraphMetaMap(): UseGraphMetaMap {
  return useMemo(() => {
    const graphMetaMap = {
      [AnalyticsDashletType.Line]: {
        icon: <GraphIcon />,
        Component: XYChartDashletForm as GraphComponentType,
        ...xyChartParamsUtils,
      },
      [AnalyticsDashletType.Bar]: {
        icon: <BarsIcon />,
        Component: XYChartDashletForm as GraphComponentType,
        ...xyChartParamsUtils,
      },
      [AnalyticsDashletType.Area]: {
        icon: <AreaIcon />,
        Component: XYChartDashletForm as GraphComponentType,
        ...xyChartParamsUtils,
      },
      [AnalyticsDashletType.Table]: {
        icon: <TableIcon />,
        Component: TableDashletForm as GraphComponentType,
        ...tableParamsUtils,
      },
      [AnalyticsDashletType.Heatmap]: {
        icon: <HeatMapIcon />,
        Component: HeatmapDashletForm as GraphComponentType,
        ...heatmapParamsUtils,
      },
      [AnalyticsDashletType.Donut]: {
        icon: <BagelIcon />,
        Component: XYChartDashletForm as GraphComponentType,
        ...donutParamsUtils,
      },
      [AnalyticsDashletType.ConfusionMatrix]: {
        icon: <ConfusionMatrixIcon />,
        Component: ConfusionMatrixForm as GraphComponentType,
        ...confusionMatrixParamsUtils,
      },
    };
    const tabItems = Object.entries(graphMetaMap).map(([label, { icon }]) => ({
      label,
      icon,
    })) as { label: AnalyticsDashletType; icon: JSX.Element }[];
    return {
      graphMetaMap,
      tabItems,
    };
  }, []);
}

export function DashletFormHeader({
  graphType,
  reset,
  cancel,
}: DashletFormHeaderProps): JSX.Element {
  const header = useMemo(() => `edit ${graphType} dashlet`, [graphType]);

  return (
    <div className="flex items-center">
      <DashletFormParagraph className="flex-1" content={header} />
      <Button variant="text" type="button" onClick={reset} disabled={!reset}>
        Reset
      </Button>
      <Button variant="action-icon" onClick={cancel}>
        <XClose className="h-5 w-5" />
      </Button>
    </div>
  );
}

export function GraphAxis<GraphAxisParams extends Record<string, unknown>>({
  form: { control },
  fields,
  defaultNumericField,
  defaultAggregatableField,
}: DashletFormProps<GraphAxisParams>) {
  const {
    field: { ref: _yAxisRef, ...yAxisField },
  } = useController({
    control,
    name: ('data.yAxis' as unknown) as 'name',
    defaultValue: defaultNumericField,
  });
  const {
    field: { ref: _xAxisRef, ...xAxisField },
  } = useController({
    control,
    name: ('data.xAxis' as unknown) as 'name',
    defaultValue: defaultAggregatableField,
  });

  return (
    <div className="flex flex-row gap-3">
      <Select
        label="Y-AXIS"
        options={fields.numericFields}
        inactiveOptions={fields.notActiveFields}
        className="flex-1"
        {...yAxisField}
      />

      <Select
        label="X-AXIS"
        options={fields.aggregatableFields}
        inactiveOptions={fields.notActiveFields}
        className="flex-1"
        {...xAxisField}
      />
    </div>
  );
}

export function SelectDistributionField<
  DistributionParams extends Record<string, unknown>
>({
  form: { control },
  name,
  fields,
  axisField,
}: DashletFormProps<DistributionParams> & {
  name?: string;
  axisField: string;
}): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: (`data.${name || 'dataDistribution'}` as unknown) as 'name',
    defaultValue: 'distinct',
  });

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (fields.numericFields.includes(axisField)) {
      setDisabled(false);
    } else {
      field.onChange('distinct');
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axisField, fields]);

  return (
    <Select
      disabled={disabled}
      label="DATA DISTRIBUTION"
      options={dataDistributionOptions}
      {...field}
      className="flex-1"
    />
  );
}

export function SelectOrderField<OrderParams extends Record<string, unknown>>({
  form: { control },
  name,
  dataDistribution = 'continuous',
}: DashletFormProps<OrderParams> & {
  name?: string;
  dataDistribution: DataDistributionType;
}): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: (`data.${name || 'order'}` as unknown) as 'name',
    defaultValue: OrderType.Desc,
  });

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (dataDistribution === 'distinct') {
      setDisabled(false);
    } else {
      field.onChange(OrderType.Asc);
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDistribution]);

  return (
    <Select
      disabled={disabled}
      label="ORDER"
      {...field}
      options={orderOptions}
      className="flex-1"
    />
  );
}

export function SelectOrderByField<
  SelectOrderByParams extends Record<string, unknown>
>({
  form: { control },
  fieldName,
  aggregation = '',
  dataDistribution = 'continuous',
  name = 'orderBy',
}: DashletFormProps<SelectOrderByParams> & {
  fieldName?: string;
  aggregation: string;
  dataDistribution: DataDistributionType;
  name?: string;
}) {
  const metricOptionLabel = `Metric: ${aggregation} ${fieldName}`;
  const options = [
    { value: '1', label: metricOptionLabel },
    { value: '_key', label: ALPHBETICAL },
  ];

  const {
    field: { ref: _orderByRef, ...orderBy },
  } = useController({
    control,
    name: (`data.${name}` as unknown) as 'name',
    defaultValue: options[0].value,
  });

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (dataDistribution === 'distinct') {
      setDisabled(false);
    } else {
      orderBy.onChange(options[1].value);
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDistribution]);

  return (
    <Select
      disabled={disabled}
      label="ORDER-BY"
      options={options}
      className="flex-1"
      {...orderBy}
    />
  );
}

export function InputSizeIntervalField<
  InputSizeIntervalFieldParams extends Record<string, unknown>
>({
  form: { control },
  dataDistribution = 'continuous',
  name,
}: DashletFormProps<InputSizeIntervalFieldParams> & {
  dataDistribution: DataDistributionType;
  name?: string;
}) {
  const {
    field: { ref: _fieldRef, ...field },
  } = useController({
    control,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: `data.${name}` as any,
    defaultValue: 10,
  });

  const [label, setLabel] = useState<'Size' | 'Interval'>('Interval');

  useEffect(() => {
    setLabel(dataDistribution === 'distinct' ? 'Size' : 'Interval');
  }, [dataDistribution]);

  return <Input type="number" label={label} min={1} {...field} />;
}

export type OrientationParams = {
  orientation: OrientationType;
};

export function SelectOrientationField<
  OrientationParams extends Record<string, unknown>
>({ form: { control } }: DashletFormProps<OrientationParams>): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: `data.orientation` as 'name',
    defaultValue: OrientationType.Horizontal,
  });
  return <Select label="ORIENTATION" {...field} options={orientationOptions} />;
}

export function SelectAggregationField<
  AggregationParams extends Record<string, unknown>
>({ form: { control } }: DashletFormProps<AggregationParams>): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: 'data.aggregation' as 'name',
    defaultValue: AggregationMethod.Average,
  });
  return (
    <Select
      label="AGGREGATION"
      options={Object.values(AggregationMethod)}
      {...field}
      className="flex-1"
    />
  );
}

export function SelectAggregationMethodField<
  AggregationParams extends Record<string, unknown>
>({ form: { control } }: DashletFormProps<AggregationParams>): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: 'data.aggregation' as 'name',
    defaultValue: AggregationMethod.Average,
  });
  return (
    <Select
      label="AGGREGATION"
      options={Object.keys(AggregationMethod)}
      {...field}
      className="flex-1"
    />
  );
}

export type SliceSizeByParams = {
  sliceSizeBy: SliceSizeByType;
};

export function SliceSizeByField<
  SliceSizeByParams extends Record<string, unknown>
>({ form: { control } }: DashletFormProps<SliceSizeByParams>): JSX.Element {
  const {
    field: { ref: _ref, ...field },
  } = useController({
    control,
    name: ('data.sliceSizeBy' as unknown) as 'name',
    defaultValue: SliceSizeByType.Count,
  });
  return (
    <Select label="SLICE SIZE BY" options={SliceSizeByOptions} {...field} />
  );
}

export interface DashletFormTextFieldProps {
  title: string;
  value: string;
  setValue: (value: string) => void;
}

function columnIndexToString(index: number) {
  return `${index < 9 ? '0' : ''}${index + 1}`;
}

const IsContIconClassName = 'my-auto';
function IsContIcon({ continuous }: { continuous: boolean }): JSX.Element {
  return continuous ? (
    <IntervalIcon className={IsContIconClassName} />
  ) : (
    <SizeIcon2 className={IsContIconClassName} />
  );
}

export interface MetaDataTableProps {
  fields: DashletFields;
  defaultAggregatableField: string;
  entities: MetaDataTableEntity[];
  onEntitiesChange: (_: MetaDataTableEntity[]) => void;
  className?: string;
}

type UseWithIndexReturn<T> = {
  entitiesWithIndex: WithIndex<T>[];
  onRemove: (item: WithIndex<T>) => void;
  onItemChange: OnItemChange<WithIndex<T>>;
};
function toArrayIndex(index: string): number {
  return Number(index) - 1;
}

function useWithIndex<T>(
  entities: T[],
  onEntitiesChange: (_: T[]) => void
): UseWithIndexReturn<T> {
  const onRemove = useCallback(
    (item: WithIndex<T>) => {
      const itemIndex = toArrayIndex(item.index);

      const filteredEntities = entities.filter(
        (_, index) => index !== itemIndex
      );
      onEntitiesChange(filteredEntities);
    },
    [onEntitiesChange, entities]
  );

  const onItemChange = useCallback<OnItemChange<WithIndex<T>>>(
    async ({ currentModel }) => {
      const newEntities = [...entities];
      const itemIndex = toArrayIndex(currentModel.index);
      newEntities[itemIndex] = currentModel;
      onEntitiesChange(newEntities);
    },
    [onEntitiesChange, entities]
  );

  const entitiesWithIndex = useMemo(
    () => entities.map((r, i) => ({ ...r, index: columnIndexToString(i) })),
    [entities]
  );

  return {
    entitiesWithIndex,
    onRemove,
    onItemChange,
  };
}

const indexColumn = {
  label: '#',
  accessorKey: 'index',
  table: {
    width: 30,
  },
} as const;

function deleteRowColumn<Item>(
  remove: (item: Item) => void
): ComputedModelField<Item> {
  return {
    label: '',
    format: (item) => (
      <IconButton
        size="small"
        onClick={() => {
          remove(item);
        }}
      >
        <XClose className="w-4 h-4" />
      </IconButton>
    ),
  };
}

type WithIndex<T> = T & { index: string };

export function MetaDataTable({
  fields,
  defaultAggregatableField,
  entities,
  onEntitiesChange,
  className,
}: MetaDataTableProps): JSX.Element {
  const { entitiesWithIndex, onRemove, onItemChange } = useWithIndex(
    entities,
    onEntitiesChange
  );
  const columns = useMemo(() => {
    const { editableField } = createFieldHelper<
      WithIndex<MetaDataTableEntity>
    >();

    const onChange = builtOnValueChange(onItemChange);

    const columns: ModelFields<WithIndex<MetaDataTableEntity>> = [
      { ...indexColumn },
      {
        label: 'FIELD',
        accessorKey: 'field',
        format: (field, item, key) => (
          <Select
            value={field}
            required
            clean
            small
            onChange={(field) => {
              if (!field) throw Error(`'${key}' must be required`);
              const currentItem = { ...item, [key]: field };
              if (
                currentItem.continuous &&
                !fields.numericFields.includes(field)
              ) {
                currentItem.continuous = false;
              }
              onItemChange({
                previousModel: item,
                currentModel: currentItem,
                changeKey: key,
                changeValue: '',
              });
            }}
            options={fields.aggregatableFields}
          />
        ),
      },
      editableField('continuous', {
        label: 'CONT.',
        table: {
          width: 80,
          align: 'center',
        },
        onChange,
        editable: ({ value, item, onChange }) => {
          const isNumeric = fields.numericFields.includes(item.field);

          return (
            <Switch disabled={!isNumeric} value={value} onClick={onChange} />
          );
        },
      }),
      editableField('size', {
        label: 'SIZE/INTERVAL',
        table: {
          width: 70,
        },
        onChange,
        editable: ({ value, item, onChange }) => {
          const [label, tooltip] = item.continuous
            ? ['INTER.', 'INTERVAL']
            : ['SIZE', 'SIZE'];

          return (
            <div className="flex flex-row gap-2">
              <Tooltip title={tooltip} arrow placement="top">
                <span className="flex">
                  <IsContIcon continuous={item.continuous} />
                </span>
              </Tooltip>
              <Input
                type="number"
                label={label}
                value={value}
                clean
                small
                min={1}
                onChange={(e) => {
                  const numberValue = Number(e.target.value);
                  onChange(numberValue);
                }}
              />
            </div>
          );
        },
      }),
      deleteRowColumn(onRemove),
    ];
    return columns;
  }, [fields, onRemove, onItemChange]);

  const add = useCallback(() => {
    onEntitiesChange(
      entities.concat({
        field: defaultAggregatableField,
        continuous: false,
        size: 10,
      })
    );
  }, [entities, onEntitiesChange, defaultAggregatableField]);

  return (
    <div className={clsx('flex flex-col', className)}>
      <TableHeader add={add} title="MetaData" />

      <Table
        fields={columns}
        data={entitiesWithIndex}
        noData={<span className="uppercase">no metadata </span>}
        headersBgClass="bg-gray-900"
      />
    </div>
  );
}
type TableHeaderProps = { title: string; add: () => void };
function TableHeader({ add, title }: TableHeaderProps) {
  return (
    <div className="flex flex-row justify-between">
      <DashletFormParagraph className="pt-3" content={title} />
      <div className="flex flex-row">
        <ActionButton icon={<Plus className="h-3 w-3" />} onRun={add}>
          Add
        </ActionButton>
      </div>
    </div>
  );
}

export interface MetricsTableProps {
  options: OptionType[];
  defaultAggregatableField: string;
  entities: MetricTableEntity[];
  setEntities: Setter<MetricTableEntity[]>;
}

export function MetricsTable({
  options,
  defaultAggregatableField,
  entities,
  setEntities,
}: MetricsTableProps): JSX.Element {
  const { entitiesWithIndex, onRemove, onItemChange } = useWithIndex(
    entities,
    setEntities
  );

  const columns = useMemo(() => {
    const { editableField } = createFieldHelper<WithIndex<MetricTableEntity>>();

    const onChange = builtOnValueChange(onItemChange);

    const columns: ModelFields<WithIndex<MetricTableEntity>> = [
      { ...indexColumn },
      editableField('field', {
        label: 'METRIC',
        onChange,
        editable: (props) => (
          <Select clean small {...props} options={options} />
        ),
      }),
      editableField('aggregation', {
        label: 'AGGREGATION',
        table: {
          width: 100,
        },
        onChange,
        editable: ({ value, onChange }) => (
          <Select
            value={value}
            clean
            small
            onChange={(_, value) => {
              value && onChange(value);
            }}
            options={Object.values(AggregationMethod)}
          />
        ),
      }),
      deleteRowColumn(onRemove),
    ];

    return columns;
  }, [options, onRemove, onItemChange]);

  const add = useCallback(() => {
    setEntities(
      entities.concat({
        field: defaultAggregatableField,
        aggregation: AggregationMethod.Average,
      })
    );
  }, [entities, setEntities, defaultAggregatableField]);

  return (
    <div className="flex flex-col">
      <TableHeader add={add} title="Metrics" />

      <Table
        fields={columns}
        data={entitiesWithIndex}
        headersBgClass="bg-gray-900"
        noData={<span className="uppercase">no metrics </span>}
      />
    </div>
  );
}

export type showAllEpochsProps = {
  showAllEpochs: boolean;
};

export function ShowAllEpochs({
  form: { control },
}: DashletFormProps<showAllEpochsProps>) {
  const {
    field: { ref: _showAllEpochsRef, ...showAllEpochsField },
  } = useController({
    control,
    name: 'data.showAllEpochs',
    defaultValue: true,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={showAllEpochsField.value}
        onClick={() => {
          showAllEpochsField.onChange(!showAllEpochsField.value);
        }}
      />
      <p className="flex-1 my-2">Show all epochs</p>
    </div>
  );
}

export type SplitBySubsetProps = {
  splitSeriesBySubset: boolean;
};

export function SplitBySubset({
  form: { control },
}: DashletFormProps<SplitBySubsetProps>) {
  const {
    field: { ref: _splitSeriesRef, ...splitSeriesField },
  } = useController({
    control,
    name: 'data.splitSeriesBySubset',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={splitSeriesField.value}
        onClick={() => {
          splitSeriesField.onChange(!splitSeriesField.value);
        }}
      />
      <p className="flex-1 my-2 ">Split series by subset</p>
    </div>
  );
}

export type AutoScaleYProps = {
  autoScaleY: boolean;
};

export function AutoScaleY({
  form: { control },
}: DashletFormProps<AutoScaleYProps> & { defaultValue?: boolean }) {
  const {
    field: { ref: _autoScaleYRef, ...autoScaleYField },
  } = useController({
    control,
    name: 'data.autoScaleY',
    defaultValue: true,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={autoScaleYField.value}
        onClick={() => {
          autoScaleYField.onChange(!autoScaleYField.value);
        }}
      />
      <p className="flex-1 my-2 ">Auto scale Y</p>
    </div>
  );
}

export type ShowAllAvgPrecisionsProps = {
  showAllAvgPrecisions: boolean;
};

export function ShowAllAveragePrecisions({
  form: { control },
}: DashletFormProps<ShowAllAvgPrecisionsProps> & { defaultValue?: boolean }) {
  const {
    field: { ref: _showAllAvgPrecisionsRef, ...showAllAvgPrecisions },
  } = useController({
    control,
    name: 'data.showAllAvgPrecisions',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={showAllAvgPrecisions.value}
        onClick={() => {
          showAllAvgPrecisions.onChange(!showAllAvgPrecisions.value);
        }}
      />
      <p className="flex-1 my-2 ">Show all avg precisions</p>
    </div>
  );
}

export type ShowPercentageProps = {
  showPercentages: boolean;
};
export function ShowPercentage({
  form: { control },
}: DashletFormProps<ShowPercentageProps> & { defaultValue?: boolean }) {
  const {
    field: { ref: _showPercentageRef, ...showPercentageField },
  } = useController({
    control,
    name: 'data.showPercentages',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={showPercentageField.value}
        onClick={() => {
          showPercentageField.onChange(!showPercentageField.value);
        }}
      />
      <p className="flex-1 my-2 ">Show in percentages</p>
    </div>
  );
}

export type SplitByLabelProps = {
  splitByLabel: boolean;
  splitByLabelOrder: OrderType;
};
export function SplitByLabel({
  form: { control },
}: DashletFormProps<SplitByLabelProps> & { defaultValue?: boolean }) {
  const {
    field: { ref: _splitByLabelRef, ...splitByLabelField },
  } = useController({
    control,
    name: 'data.splitByLabel',
    defaultValue: false,
  });
  const {
    field: { ref: _splitByLabelOrderRef, ...splitByLabelOrderField },
  } = useController({
    control,
    name: 'data.splitByLabelOrder',
    defaultValue: OrderType.Asc,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={splitByLabelField.value}
        onClick={() => {
          splitByLabelField.onChange(!splitByLabelField.value);
        }}
      />
      <p className="flex-1 my-2 ">Split by label</p>
      <Tooltip
        title={`Switch to ${
          splitByLabelOrderField.value === OrderType.Asc
            ? 'Descending'
            : 'Ascending'
        }`}
      >
        <IconButton
          onClick={() =>
            splitByLabelOrderField.onChange(
              splitByLabelOrderField.value === OrderType.Asc
                ? OrderType.Desc
                : OrderType.Asc
            )
          }
        >
          <SortBigSmallIcon
            className={clsx(
              'h-6 w-6 transition-transform duration-300 ease-in-out',
              splitByLabelOrderField.value === OrderType.Asc && '-scale-y-100'
            )}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export type ConfusionMatrixLabelSelectorProps = {
  confusionMatrixLabelsFilter: string[];
};
export function ConfusionMatrixLabelSelector({
  form: { control },
  selectedConfusionMatrixKey,
}: DashletFormProps<ConfusionMatrixLabelSelectorProps> & {
  defaultValue?: string[];
  selectedConfusionMatrixKey: string;
}) {
  const {
    field: {
      ref: _confusionMatrixLabelsFilterRef,
      ...confusionMatrixLabelsFilterField
    },
  } = useController({
    control,
    name: 'data.confusionMatrixLabelsFilter',
    defaultValue: [],
  });
  const { labelsByPrediction } = useGetConfusionMatrixLabels();
  const options = useMemo(() => {
    if (
      !labelsByPrediction ||
      !labelsByPrediction.length ||
      !labelsByPrediction
        .map(({ predictionName }) => predictionName)
        .includes(selectedConfusionMatrixKey)
    )
      return [];
    const labels = labelsByPrediction.find(
      ({ predictionName }) => predictionName === selectedConfusionMatrixKey
    )?.labels;
    return labels || [];
  }, [labelsByPrediction, selectedConfusionMatrixKey]);

  return (
    <div className="flex w-full">
      <SelectMultiple
        label="LABELS"
        className="flex w-full max-w-[21rem]"
        options={options}
        {...confusionMatrixLabelsFilterField}
      />
    </div>
  );
}

export type AbsAxisProps = {
  absAxis?: boolean;
};

export function AbsAxis({ form: { control } }: DashletFormProps<AbsAxisProps>) {
  const {
    field: { ref: _absAxisRef, ...absAxisField },
  } = useController({
    control,
    name: 'data.absAxis',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={absAxisField.value}
        onClick={() => {
          absAxisField.onChange(!absAxisField.value);
        }}
      />
      <p className="flex-1 my-2 ">Absolute Axis</p>
    </div>
  );
}

export type SplitByPredictionProps = {
  splitByPrediction: boolean;
};

export function SplitByPrediction({
  form: { control },
}: DashletFormProps<SplitByPredictionProps>) {
  const {
    field: { ref: _splitByPredictionRef, ...splitByPrediction },
  } = useController({
    control,
    name: 'data.splitByPrediction',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={splitByPrediction.value}
        onClick={() => {
          splitByPrediction.onChange(!splitByPrediction.value);
        }}
      />
      <p className="flex-1 my-2 ">Split by prediction</p>
    </div>
  );
}

export type FlipColorRangeProps = {
  flipColorRange: boolean;
};

export function FlipcolorRange({
  form: { control },
}: DashletFormProps<FlipColorRangeProps>) {
  const {
    field: { ref: _flipColorRangeRef, ...flipColorRange },
  } = useController({
    control,
    name: 'data.flipColorRange',
    defaultValue: false,
  });

  return (
    <div className="flex flex-row gap-1">
      <Switch
        value={flipColorRange.value}
        onClick={() => {
          flipColorRange.onChange(!flipColorRange.value);
        }}
      />
      <p className="flex-1 my-2 ">Flip Colors</p>
    </div>
  );
}

const DEFAULT_SPLIT_INTERVAL = 1;
const DEFAULT_SPLIT_DISTRIBUTION = 'distinct';
export function Split({
  label,
  value,
  dashletFields,
  onChange,
}: {
  label: string;
  value: ChartSplit;
  dashletFields: DashletFields;
  onChange: (value: ChartSplit) => void;
}): JSX.Element {
  const filedOptions = useMemo(
    () => [
      NO_SPLIT_SELECTED,
      ...(dashletFields?.aggregatableFields || []),
      ...(dashletFields?.numericFields || []),
    ],
    [dashletFields]
  );
  const onObjChange = <K extends keyof ChartSplit>(
    name: K,
    fieldValue: ChartSplit[K]
  ) => {
    const newValue = { ...value, [name]: fieldValue };

    const isNotNumeric = !dashletFields.numericFields.includes(newValue.field);
    if (isNotNumeric) {
      newValue.distribution = undefined;
      newValue.interval = undefined;
    } else if (newValue.distribution === 'continuous') {
      if (!newValue.interval || newValue.interval <= 0) {
        newValue.interval = DEFAULT_SPLIT_INTERVAL;
      }
    } else {
      newValue.interval = undefined;
    }
    onChange(newValue);
  };

  const showSplitType = useMemo(() => {
    return !dashletFields.numericFields.includes(value.field);
  }, [value.field, dashletFields]);

  const [intervalStr, setIntervalStr] = useState<string | undefined>('');
  const [intervalInFocus, setIntervalInFocus] = useState<boolean>(false);

  useEffect(() => {
    if (intervalInFocus) {
      return;
    }
    setIntervalStr(value.interval?.toString() ?? '');
  }, [value.interval, intervalInFocus]);

  const isIntervalDisabled =
    showSplitType || value.distribution !== 'continuous';

  return (
    <>
      <span className="capitalize">{label}:</span>
      <div className="flex items-center gap-1 justify-between">
        <Select
          label="Field"
          options={filedOptions}
          className="flex-1"
          value={value.field ?? NO_SPLIT_SELECTED}
          inactiveOptions={dashletFields.notActiveFields}
          onChange={(field = NO_SPLIT_SELECTED) => onObjChange('field', field)}
        />
        <Tooltip
          title={`Switch to ${
            value.order === OrderType.Asc ? 'Descending' : 'Ascending'
          }`}
        >
          <IconButton
            onClick={() =>
              onObjChange(
                'order',
                value.order === OrderType.Asc ? OrderType.Desc : OrderType.Asc
              )
            }
          >
            <SortBigSmallIcon
              className={clsx(
                'h-6 w-6 transition-transform duration-300 ease-in-out',
                value.order === OrderType.Asc && '-scale-y-100'
              )}
            />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={clsx(
          showSplitType ? 'hidden' : 'flex',
          'items-center gap-2 justify-between'
        )}
      >
        <ToggleButtonGroup
          disabled={showSplitType}
          options={dataDistributionOptions}
          className="h-11"
          value={value.distribution ?? DEFAULT_SPLIT_DISTRIBUTION}
          onChange={(option) => onObjChange('distribution', option)}
        />
        <Input
          disabled={isIntervalDisabled}
          type="number"
          label="Interval"
          value={intervalStr}
          className="w-40"
          onFocus={() => setIntervalInFocus(true)}
          onBlur={() => {
            setIntervalInFocus(false);
          }}
          onChange={(e) => {
            const value = e.target.value;
            setIntervalStr(value);
            const interval = Number(value);
            onObjChange('interval', isNaN(interval) ? undefined : interval);
          }}
        />
      </div>
    </>
  );
}
