import {
  ContinuesAgg,
  DistinctAgg,
  OrderType,
  SessionRunToEpoch,
  SplitAgg,
} from '@tensorleap/api-client';
import { SplitPosition } from '../form/utils';
import { ChartSplit, DataDistributionType } from '../types';
import { SelectedSessionRun } from '../../../../ui/molecules/useModelFilter';
import { SessionRunDataAndEpoch } from '../../../../core/VersionControlContext';

export const NO_SPLIT_SELECTED = '[--NONE SELECTED--]';
export const TARGET_SESSION_MODEL_PARAM_NAME = 'model._id.keyword';

type _GetSplitLabelsResponse = {
  verticalSplit: ChartSplit | undefined;
  horizontalSplit: ChartSplit | undefined;
  innerSplit: ChartSplit | undefined;
};

export type GetSplitLabelsResponse = {
  verticalSplit: SplitAgg | undefined;
  horizontalSplit: SplitAgg | undefined;
  innerSplit: SplitAgg | undefined;
};

export function isSplitDefined<T extends { field: string }>(
  splitValue?: T
): splitValue is T {
  return !!splitValue && splitValue.field !== NO_SPLIT_SELECTED;
}

export function getSplitValueOrUndefined(
  splitValue: ChartSplit | undefined
): ChartSplit | undefined {
  // backward compatibility
  if (typeof splitValue === 'string') {
    splitValue = { field: splitValue };
  }

  return isSplitDefined(splitValue) ? splitValue : undefined;
}

export function getSplitLabels(
  modelIdPosition: SplitPosition,
  firstSplit?: ChartSplit,
  secondSplit?: ChartSplit
): GetSplitLabelsResponse {
  const { verticalSplit, horizontalSplit, innerSplit } = mapSplits(
    modelIdPosition,
    firstSplit,
    secondSplit
  );

  return {
    verticalSplit: chartSplitToSplitAgg(verticalSplit, 5),
    horizontalSplit: chartSplitToSplitAgg(horizontalSplit, 5),
    innerSplit: chartSplitToSplitAgg(innerSplit, 20),
  };
}

function mapSplits(
  modelIdPosition?: SplitPosition,
  firstSplit?: ChartSplit,
  secondSplit?: ChartSplit
): _GetSplitLabelsResponse {
  const firstSplitOrUndefined = getSplitValueOrUndefined(firstSplit);
  const secondSplitOrUndefined = getSplitValueOrUndefined(secondSplit);
  const modelSplit = { field: TARGET_SESSION_MODEL_PARAM_NAME };

  switch (modelIdPosition) {
    case 'horizontal':
      return {
        verticalSplit: firstSplitOrUndefined,
        horizontalSplit: modelSplit,
        innerSplit: secondSplitOrUndefined,
      };
    case 'inner':
      return {
        verticalSplit: firstSplitOrUndefined,
        horizontalSplit: secondSplitOrUndefined,
        innerSplit: modelSplit,
      };
    default:
      return {
        verticalSplit: modelSplit,
        horizontalSplit: firstSplitOrUndefined,
        innerSplit: secondSplitOrUndefined,
      };
  }
}

export function toIntervalOrLimit(
  distribution: DataDistributionType | undefined,
  xAxisSizeInterval: number
) {
  return distribution === 'continuous'
    ? { interval: xAxisSizeInterval }
    : { limit: xAxisSizeInterval };
}

export function chartSplitToSplitAgg<T extends ChartSplit | undefined>(
  split: T,
  defaultSplitLimit: number | null,
  defaultInterval = 1
): T extends ChartSplit ? SplitAgg : undefined {
  type ReturnType = T extends ChartSplit ? SplitAgg : undefined;
  if (!split) {
    return undefined as ReturnType;
  }
  const {
    field,
    order = OrderType.Desc,
    distribution = 'distinct',
    interval = defaultInterval,
    limit = defaultSplitLimit,
    orderField,
  } = split;
  if (distribution === 'continuous') {
    const agg: ContinuesAgg = {
      field,
      distribution: 'continuous',
      interval,
      order,
      orderField,
      limit,
    };
    return agg as ReturnType;
  } else {
    const agg: DistinctAgg = {
      field,
      distribution: 'distinct',
      order,
      orderField,
      limit,
    };
    return agg as ReturnType;
  }
}

export function getOrderField(
  orderField: string | undefined,
  metricKey: string
): string | undefined {
  return orderField === '1' ? metricKey : orderField;
}

export function selectedSessionRunToSessionRunsToEpochs(
  sessionRuns: SelectedSessionRun[]
): SessionRunToEpoch[] {
  return sessionRuns.map(({ id, epochsState: { selectedEpoch } }) => ({
    sessionRunId: id,
    epoch: selectedEpoch,
  }));
}

export function sessionRunDataAndEpochToSessionRunsToEpochs(
  sessionRunDataAndEpoch: SessionRunDataAndEpoch
): SessionRunToEpoch[] {
  return [
    {
      sessionRunId: sessionRunDataAndEpoch.cid,
      epoch: sessionRunDataAndEpoch?.epochsState?.selectedEpoch,
    },
  ];
}
