import { Session, SlimVersion } from '@tensorleap/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { Table } from '../ui/model-list/table/Table';
import { ModelFields } from '../ui/model-list/types';
import { CloudDlIcon, DatatypeIcon, Train2Icon, Trash } from '../ui/icons';
import { HoverAction } from '../ui/model-list/table/TableRowActions';
import { useCallback, useMemo, useState } from 'react';
import { AddToDashboardToggle } from './AddToDashboardToggle';
import { SessionRunsDialog } from './SessionRunsDialog';
import { sessionHasTrainedEpochs } from '../actions-dialog/helper-functions';
import { orderBy } from 'lodash';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import api from '../core/api-client';
import { sessionTooltip } from './helper';

type ExpandedVersionRowProps = { item: SlimVersion };

export function ExpandedVersionRow({ item }: ExpandedVersionRowProps) {
  const {
    setSessionToExport,
    setSessionToTrain,
    setSessionToDelete,
    refetch,
    setSessionToShowItsEpochs,
  } = useVersionControl();

  const updateSessionName = useCallback(
    async (newName: string | undefined, session: Session) => {
      const projectId = session.projectId;
      const name = newName || '';
      await api.updateSessionName({
        cid: session?.cid,
        name,
        projectId,
      });

      if (session.sessionRuns?.length === 1) {
        await api.updateSessionRunName({
          cid: session.sessionRuns[0].cid,
          name,
          projectId,
        });
      }

      await refetch();
    },
    [refetch]
  );

  const [isCellEditing, setIsCellEditing] = useState(false);
  const handleEditChange = useCallback((edit: boolean) => {
    setIsCellEditing(edit);
  }, []);

  const MODEL_FIELDS: ModelFields<Session> = useMemo(
    () => [
      {
        label: 'sessions',
        table: { headerClassName: 'text-gray-500' },
        format(session: Session) {
          return (
            <EditableInputCell
              value={session.modelName}
              onChange={(value) => updateSessionName(value, session)}
              textWhenNotEditable={true}
              tooltip={sessionTooltip(session)}
              onEditChange={handleEditChange}
              className="w-[230px]"
            />
          );
        },
      },
    ],
    [handleEditChange, updateSessionName]
  );

  const hoverActions = useMemo<HoverAction<Session>[]>(() => {
    return [
      {
        icon: <DatatypeIcon className="text-gray-300" />,
        title: 'Show epochs',
        filter: (session: Session) => session.hasExternalEpoch,
        onSelect: (session) => setSessionToShowItsEpochs(session),
      },
      {
        icon: <CloudDlIcon className="text-gray-300" />,
        title: 'Export Model',
        filter: sessionHasTrainedEpochs,
        onSelect: setSessionToExport,
      },
      {
        icon: <Train2Icon className="text-gray-300" />,
        title: 'Continue Training',
        filter: sessionHasTrainedEpochs,
        onSelect: (session) => setSessionToTrain({ session, version: item }),
      },
      {
        icon: <Trash className="text-gray-300" />,
        title: 'Delete Session',
        onSelect: setSessionToDelete,
      },
    ];
  }, [
    setSessionToShowItsEpochs,
    setSessionToExport,
    setSessionToTrain,
    setSessionToDelete,
    item,
  ]);

  const [selectedSessionDialog, setSelectedSessionDialog] = useState<
    Session | undefined
  >(undefined);
  const deselectSessionDialog = useCallback(() => {
    setSelectedSessionDialog(undefined);
  }, []);

  const showVcSessionsPopover = useCallback(
    (session: Session) => (session?.sessionRuns?.length ?? 0) > 1,
    []
  );

  const renderToggleModel = useCallback(
    (session: Session) => {
      if (showVcSessionsPopover(session)) {
        return (
          <SessionRunsDialog
            handleClose={deselectSessionDialog}
            session={session}
            selectedSession={selectedSessionDialog}
            setSelectedSession={setSelectedSessionDialog}
          />
        );
      }
      const firstSessionRun = session?.sessionRuns?.[0];
      return <AddToDashboardToggle sessionRun={firstSessionRun} />;
    },
    [deselectSessionDialog, selectedSessionDialog, showVcSessionsPopover]
  );

  const orderedSessions = useMemo(
    () => orderBy(item.sessions, ({ createdAt }) => createdAt, 'desc'),
    [item.sessions]
  );

  return (
    <>
      <Table
        tableBgClass="bg-gray-850"
        headersBgClass="bg-gray-900 !z-0"
        inline
        fields={MODEL_FIELDS}
        data={orderedSessions}
        customActions={renderToggleModel}
        hoverActions={hoverActions}
        showHoverActions={!isCellEditing}
      />
    </>
  );
}
