import { HoveredLegendFilter, useScatterData } from '../ScatterDataContext';
import { createShapeMapping } from '../useScatterMapData';
import { useCallback, useMemo } from 'react';
import { LabelsLegendMenu } from '../../ui/charts/legend/LabelsLegendMenu';
import { sum } from 'lodash';
import {
  MutualInformationElement,
  NumberOrString,
} from '@tensorleap/api-client';
import { Setter } from '../../core/types';
import { ShapeType } from './ScatterShape';
import { LegendItem } from '../../ui/charts/legend/LegendItem';

type Legend = {
  shape: ShapeType;
  name: NumberOrString;
  value: NumberOrString | NumberOrString[];
};

export type ScatterLegendProps = {
  onLegendClick: (
    key: string,
    value: NumberOrString | NumberOrString[]
  ) => void;
};

export function ShapesLegend({
  onLegendClick,
}: ScatterLegendProps): JSX.Element {
  const {
    scatterData,
    settings: { sizeOrShape },
    setSizeOrShapeOrderMethod: setSortMethod,
    sizeOrShapeOrderMethod: sortMethod,
    showLegendNames: showNames,
    toggleShowLegendNames: toggleShowNames,
    legendTruncatedLongtail: truncatedLongtail,
    setLegendTruncatedLongtail: setTruncatedLongtail,
    miByCluster,
    setLegendHovered,
  } = useScatterData();
  const { legends, appearance } = useMemo(() => {
    if (sizeOrShape === undefined)
      return { legends: [], appearance: undefined };
    const sizeOrShapeSubjectMetadata = scatterData.metadata[sizeOrShape];
    const {
      metadataToShape,
      metadataInOtherGroup,
      appearance,
    } = createShapeMapping(sizeOrShapeSubjectMetadata.body, sortMethod);

    const otherGroupNames = `(${Array.from(metadataInOtherGroup).join(', ')})`;
    const shapeToLegendName = new Map<ShapeType, string | number>();
    let otherShape: ShapeType | undefined;
    metadataToShape.forEach((shape, metadata) => {
      if (metadataInOtherGroup.has(metadata)) {
        otherShape = shape;
        return;
      }
      shapeToLegendName.set(shape, metadata);
    });
    const legends: Legend[] = Array.from(shapeToLegendName.entries()).map(
      ([shape, metadata]) => {
        return { shape, name: metadata, value: metadata };
      }
    );
    if (otherShape) {
      legends.push({
        shape: otherShape,
        name: otherGroupNames,
        value: Array.from(metadataInOtherGroup),
      });
    }
    if (otherGroupNames) {
      const otherSum = sum(
        Array.from(metadataInOtherGroup).map(
          (metadata) => appearance.get(metadata) || 0
        )
      );
      appearance.set(otherGroupNames, otherSum);
    }
    return {
      legends,
      appearance,
    };
  }, [scatterData, sizeOrShape, sortMethod]);

  const clusterData =
    sizeOrShape && miByCluster ? miByCluster[sizeOrShape] : undefined;

  const _onLegendClick = useCallback(
    (value: NumberOrString | NumberOrString[]) => {
      sizeOrShape && onLegendClick(sizeOrShape, value);
    },
    [sizeOrShape, onLegendClick]
  );

  return (
    <div className="flex items-end flex-col z-40">
      <LabelsLegendMenu
        truncateLongtail={truncatedLongtail}
        setTruncateLongtail={setTruncatedLongtail}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        showNames={showNames}
        toggleShowNames={toggleShowNames}
        showAppearancesOrder
      />
      {legends.map(({ shape, name, value }) => (
        <ShapeLegendItem
          key={shape}
          shape={shape}
          sizeOrShape={sizeOrShape}
          handleLegendClick={_onLegendClick}
          value={value}
          setLegendHovered={setLegendHovered}
          showNames={showNames}
          name={name}
          truncatedLongtail={truncatedLongtail}
          clusterData={clusterData}
          appearance={appearance}
        />
      ))}
    </div>
  );
}

interface ShapeLegendItemProps {
  shape: ShapeType;
  sizeOrShape?: string;
  value: NumberOrString | NumberOrString[];
  setLegendHovered: Setter<HoveredLegendFilter | undefined>;
  showNames: boolean;
  name: NumberOrString;
  handleLegendClick?: (value: NumberOrString | NumberOrString[]) => void;
  truncatedLongtail: number;
  clusterData?: Record<string, MutualInformationElement[]>;
  appearance?: Map<NumberOrString, number>;
}

function ShapeLegendItem({
  shape,
  sizeOrShape,
  value,
  setLegendHovered,
  handleLegendClick,
  showNames,
  name,
  truncatedLongtail,
  clusterData,
  appearance,
}: ShapeLegendItemProps): JSX.Element {
  const onEnter = useCallback(() => {
    sizeOrShape &&
      setLegendHovered({
        key: sizeOrShape,
        value,
      });
  }, [sizeOrShape, setLegendHovered, value]);

  const onLeave = useCallback(() => {
    setLegendHovered((p) => {
      return p && p.key === sizeOrShape && p.value === value ? undefined : p;
    });
  }, [sizeOrShape, setLegendHovered, value]);

  return (
    <LegendItem
      handleLegendClick={handleLegendClick}
      handleLegendMouseOver={onEnter}
      shape={shape}
      handleLegendMouseLeave={onLeave}
      label={name}
      value={value}
      showNames={showNames}
      truncatedLongtail={truncatedLongtail}
      appearances={appearance}
      clusterData={clusterData}
    />
  );
}
