import { VisualizedItem } from '@tensorleap/api-client';
import {
  ImagePixelReader,
  StoredProjectImg,
} from '../../../core/useProjectStorage';
import { HorizontalBarGraph } from '../../../ui/HorizonalBarGraph';
import {
  HorizontalBarsIcon,
  NumberPlaceholderIcon,
  TextPlaceholderIcon,
} from '../../../ui/icons';
import { ImageWithBboxAndMapUrl } from '../../../ui/img-vis/ImageWithBbox';
import { ImageWithSegmentationAndMapUrl } from '../../../ui/img-vis/ImageWithSegmentation';
import { MultiLineGraph, MultiLineGraphZoomable } from '../../../ui/LineGraph';
import { TextHeatMap } from '../../../ui/TextHeatMap';
import { TextWithSegmentation } from '../../../ui/TextWithSegmentation';
import { ImageWithHeatmap } from '../../../ui/img-vis/ImageWithHeatmap';
import { first } from 'lodash';
import { DisplayHeatmap } from '../../SampleAnalysisView/HeatMap';
import {
  isImageData,
  isImageHeatmapData,
  isImageWithBBoxData,
  isImageWithSegmentaionData,
  isTextWithSegmentaionData,
  isHorizontalBarData,
  isTextData,
  isGraphData,
  isGradAnalysisData,
  isCompositeVisData,
  VisDataTypes,
  VisPayloadType,
} from './visDataHelpers';
import { MousePosition } from '../../../core/useSelectionGroup';
import clsx from 'clsx';
import { TransformComponent, useControls } from 'react-zoom-pan-pinch';
import { useEffect } from 'react';

export interface DisplayVisualizedItemProps {
  visualizedItem?: VisualizedItem;
  mousePosition: MousePosition;
}
export function DisplayVisualizedItem({
  visualizedItem,
  mousePosition,
}: DisplayVisualizedItemProps): JSX.Element {
  if (!visualizedItem) {
    return <NumberPlaceholderIcon />;
  }
  return (
    <DisplayVisualizedItemData
      data={visualizedItem.data}
      mousePosition={mousePosition}
    />
  );
}

export interface MetadataTooltipProps {
  content: string;
  type: VisPayloadType;
}

export interface DisplayVisualizedItemDataProps {
  data: VisDataTypes;
  mousePosition?: MousePosition;
}

export function DisplayVisualizedItemData({
  data,
  mousePosition,
}: DisplayVisualizedItemDataProps): JSX.Element {
  const { resetTransform } = useControls();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetTransform, [data]);

  if (isImageData(data)) {
    return mousePosition ? (
      <ImagePixelReader pathOrSrc={data.blob} mousePosition={mousePosition} />
    ) : (
      // we do not suppose to get here without mousePosition
      <StoredProjectImg
        src={data.blob}
        className="h-full w-full object-contain m-auto min-h-0"
      />
    );
  }

  if (isImageHeatmapData(data)) {
    return (
      <ImageWithHeatmap
        srcBlob={data.blob}
        heatmapBlob={data.heatmap_blob}
        allowZoom
        className="w-full h-full flex-1 justify-center"
      />
    );
  }
  if (isImageWithBBoxData(data)) {
    return (
      <ImageWithBboxAndMapUrl
        src={data.blob}
        boundingBox={data.bounding_box}
        className="overflow-auto h-full mr-2 min-h-0"
      />
    );
  }
  if (isImageWithSegmentaionData(data)) {
    return (
      <ImageWithSegmentationAndMapUrl
        imgSrc={data.blob}
        segmentaionSrc={data.mask_blob}
        labels={data.labels}
        allowZoom
        className="h-full w-full mr-2 min-h-0"
      />
    );
  }
  if (isTextWithSegmentaionData(data)) {
    return (
      <TextWithSegmentation
        text={data.text}
        labels={data.labels}
        mask={data.mask}
        className="h-full mx-2 min-h-0"
      />
    );
  }
  if (isHorizontalBarData(data)) {
    return (
      <TransformComponent
        contentStyle={{
          height: '100%',
          width: '100%',
        }}
        wrapperStyle={{
          height: '100%',
          width: '100%',
        }}
      >
        <HorizontalBarGraph labels={data.labels} values={[data.body]} />;
      </TransformComponent>
    );
  }
  if (isTextData(data)) {
    return (
      <TextHeatMap
        text={data.body}
        heatmap={data.heatmap}
        className="!max-w-[40rem]"
      />
    );
  }
  if (isGraphData(data)) {
    return <MultiLineGraphZoomable data={data.body} heatmap={data.heatmap} />;
  }
  if (isGradAnalysisData(data) || isCompositeVisData(data)) {
    return <DisplayHeatmap visDataElement={data} />;
  }
  return (
    <div className="h-full flex justify-center items-center min-h-0">
      <div>{JSON.stringify(data)}</div>
    </div>
  );
}

export interface DisplayVisualizedItemPreviewProps {
  visualizedItem: VisualizedItem;
  className?: string;
}

export function DisplayVisualizedItemPreview({
  visualizedItem,
  className,
}: DisplayVisualizedItemPreviewProps): JSX.Element {
  if (!visualizedItem.data) {
    return <NumberPlaceholderIcon className={className} />;
  }
  return (
    <DisplayVisualizedItemDataPreview
      data={visualizedItem.data}
      className={className}
    />
  );
}

export interface DisplayVisualizedItemDataPreviewProps {
  data?: VisDataTypes;
  className?: string;
}
export function DisplayVisualizedItemDataPreview({
  data,
  className,
}: DisplayVisualizedItemDataPreviewProps): JSX.Element {
  if (!data) {
    return <NumberPlaceholderIcon className={className} />;
  }

  if (isImageData(data) || isImageWithBBoxData(data)) {
    return <StoredProjectImg src={data.blob} className={className} />;
  }
  if (isTextData(data) || isTextWithSegmentaionData(data)) {
    return <TextPlaceholderIcon className={className} />;
  }
  if (isHorizontalBarData(data)) {
    return <HorizontalBarsIcon className={className} />;
  }
  if (isGraphData(data)) {
    return (
      <MultiLineGraph
        data={data.body}
        previewMode
        className={className}
        lineGraphClassName={className}
      />
    );
  }
  if (isImageHeatmapData(data)) {
    return (
      <ImageWithHeatmap
        heatmapBlob={data.heatmap_blob}
        srcBlob={data.blob}
        showSliders={false}
        className={className}
        allowZoom={false}
      />
    );
  }
  if (isImageWithSegmentaionData(data)) {
    return (
      <ImageWithSegmentationAndMapUrl
        imgSrc={data.blob}
        segmentaionSrc={data.mask_blob}
        labels={data.labels}
        className={className}
        allowZoom={false}
        showMenu={false}
      />
    );
  }

  if (isGradAnalysisData(data)) {
    return (
      <DisplayVisualizedItemDataPreview
        data={first(data.data)?.grads}
        className={className}
      />
    );
  }
  if (isCompositeVisData(data)) {
    return (
      <DisplayVisualizedItemDataPreview
        data={first(data.data)?.data}
        className={className}
      />
    );
  }

  return <NumberPlaceholderIcon className={className} />;
}

export interface DisplayVisualizedItemDataTooltipProps {
  data?: VisDataTypes;
  className?: string;
}
export function DisplayVisualizedItemDataTooltip({
  data,
  className,
}: DisplayVisualizedItemDataTooltipProps): JSX.Element {
  if (!data) {
    return <span>Loading...</span>;
  }

  if (data.type === VisPayloadType.Metadata) {
    return <MetadataTooltip data={data.content} />;
  }

  if (isTextWithSegmentaionData(data)) {
    return (
      <TextWithSegmentation
        text={data.text}
        labels={data.labels}
        mask={data.mask}
        className="h-full mx-2 min-h-0"
      />
    );
  }

  if (isImageData(data) || isImageWithBBoxData(data)) {
    return (
      <StoredProjectImg
        src={data.blob}
        className={clsx(
          'object-contain w-24 h-24 scale-[1.5] max-w-24 max-h-24',
          className
        )}
      />
    );
  }
  if (isImageHeatmapData(data)) {
    return (
      <ImageWithHeatmap
        heatmapBlob={data.heatmap_blob}
        srcBlob={data.blob}
        showSliders={false}
        allowZoom={false}
        className={clsx(
          'object-contain w-12 h-12 scale-[1.5] max-w-20 max-h-20',
          className
        )}
      />
    );
  }
  if (isImageWithSegmentaionData(data)) {
    return (
      <ImageWithSegmentationAndMapUrl
        imgSrc={data.blob}
        segmentaionSrc={data.mask_blob}
        labels={data.labels}
        allowZoom={false}
        className={clsx(
          'object-contain w-12 h-12 scale-[1.5] max-w-20 max-h-20',
          className
        )}
        showMenu={false}
      />
    );
  }
  if (isTextData(data)) {
    if (data.heatmap) {
      return (
        <TextHeatMap
          text={data.body}
          heatmap={data.heatmap}
          showSlider={false}
        />
      );
    }
    return (
      <span className="w-12 h-12 max-w-2xl max-h-2xl">
        {data.body?.join(' ')}
      </span>
    );
  }
  if (isHorizontalBarData(data)) {
    return (
      <div className="w-72 h-60">
        <div className="w-72 h-[266px] -mt-4">
          <HorizontalBarGraph labels={data.labels} values={[data.body]} />
        </div>
      </div>
    );
  }
  if (isGraphData(data)) {
    return (
      <MultiLineGraph
        data={data.body}
        previewMode
        className="h-14 w-20 m-0 p-0"
        lineGraphClassName="h-14 w-20 m-0 p-0"
      />
    );
  }
  if (isGradAnalysisData(data)) {
    return (
      <DisplayVisualizedItemDataTooltip
        data={first(data.data)?.grads}
        className={className}
      />
    );
  }
  if (isCompositeVisData(data)) {
    return (
      <DisplayVisualizedItemDataTooltip
        data={first(data.data)?.data}
        className={className}
      />
    );
  }

  return <span>Unsupported tooltip type</span>;
}

function MetadataTooltip({ data }: { data: string }) {
  return <span className="rounded-lg p-1 w-92 text-base">{data}</span>;
}
