import { ConfusionMatrixParams } from '@tensorleap/api-client';
import { XYChartVizProps } from './interfaces';
import { MultiCharts } from '../../../../ui/charts/visualizers/MultiCharts';
import {
  chartSplitToSplitAgg,
  getSplitLabels,
  selectedSessionRunToSessionRunsToEpochs,
  toIntervalOrLimit,
} from './utils';
import api from '../../../../core/api-client';
import { useGetChart } from '../../../../core/data-fetching/getChart';
import { useMergedObject } from '../../../../core/useMergedObject';
import { useMemo } from 'react';

export function F1Vis({
  graphParams: {
    xAxis: xField,
    metricName,
    dataDistribution,
    orderBy,
    order,
    xAxisSizeInterval,
    modelIdPosition,
    firstSplit,
    secondSplit,
    autoScaleY,
  },
  filters,
  sessionRuns,
  onFiltersChange,
  chartType,
  projectId,
}: XYChartVizProps) {
  const { innerSplit, verticalSplit, horizontalSplit } = useMemo(
    () => getSplitLabels(modelIdPosition, firstSplit, secondSplit),
    [modelIdPosition, firstSplit, secondSplit]
  );

  const sessionRunsToEpochs = useMemo(
    () => selectedSessionRunToSessionRunsToEpochs(sessionRuns),
    [sessionRuns]
  );

  const xAxis = useMemo(
    () =>
      chartSplitToSplitAgg(
        {
          field: xField,
          distribution: dataDistribution,
          orderField: orderBy,
          order,
          ...toIntervalOrLimit(dataDistribution, Number(xAxisSizeInterval)),
        },
        null
      ),
    [xField, dataDistribution, orderBy, order, xAxisSizeInterval]
  );
  const params = useMergedObject<ConfusionMatrixParams>({
    projectId,
    x: xAxis,
    sessionRunsToEpochs,
    customMetricName: metricName,
    filters,
    verticalSplit,
    innerSplit,
    horizontalSplit,
  });

  const chartRequestData = {
    xField,
    yField: 'F1',
    dataDistribution: 'continuous' as const,
    orderByParam: orderBy,
    orderParams: order,
  };

  const { multiChartsResponse, isLoading, error } = useGetChart({
    params,
    func: async (x) => await api.getF1Score(x),
  });

  return (
    <MultiCharts
      xyChartsResponse={multiChartsResponse}
      chartRequestData={chartRequestData}
      onFiltersChange={onFiltersChange}
      filters={filters}
      chartType={chartType}
      autoScaleY={autoScaleY}
      isLoading={isLoading}
      error={error}
      horizontalSplit={horizontalSplit ?? null}
      verticalSplit={verticalSplit ?? null}
    />
  );
}
