import { RocConfusionMatrixParams } from '@tensorleap/api-client';
import { MultiCharts } from '../../../../ui/charts/visualizers/MultiCharts';
import {
  chartSplitToSplitAgg,
  getSplitLabels,
  selectedSessionRunToSessionRunsToEpochs,
} from './utils';
import { XYChartVizProps } from './interfaces';
import api from '../../../../core/api-client';
import { useGetChart } from '../../../../core/data-fetching/getChart';
import { ChartRequestData } from '../../../../ui/charts/common/interfaces';
import { useMergedObject } from '../../../../core/useMergedObject';
import { useMemo } from 'react';

const INTERNAL_SPLIT = chartSplitToSplitAgg({ field: 'label' }, null);

export function RocVis({
  graphParams: { modelIdPosition, firstSplit, metricName, autoScaleY, absAxis },
  filters,
  sessionRuns,
  onFiltersChange,
  chartType,
  projectId,
}: XYChartVizProps) {
  const { verticalSplit, horizontalSplit } = useMemo(
    () => getSplitLabels(modelIdPosition, firstSplit),
    [modelIdPosition, firstSplit]
  );

  const sessionRunsToEpochs = useMemo(
    () => selectedSessionRunToSessionRunsToEpochs(sessionRuns),
    [sessionRuns]
  );

  const params = useMergedObject<RocConfusionMatrixParams>({
    projectId,
    filters,
    sessionRunsToEpochs,
    customMetricName: metricName,
    verticalSplit,
    horizontalSplit,
    absAxis,
  });

  const chartRequestData = useMergedObject<ChartRequestData>({
    xField: 'FPR',
    yField: 'TPR',
    innerSplit: INTERNAL_SPLIT,
    dataDistribution: 'continuous',
  });

  const { multiChartsResponse, isLoading, error } = useGetChart({
    params,
    func: async (x) => await api.getRoc(x),
  });

  return (
    <MultiCharts
      xyChartsResponse={multiChartsResponse}
      chartRequestData={chartRequestData}
      onFiltersChange={onFiltersChange}
      filters={filters}
      chartType={chartType}
      autoScaleY={autoScaleY}
      isLoading={isLoading}
      horizontalSplit={params.horizontalSplit ?? null}
      verticalSplit={params.verticalSplit ?? null}
      error={error}
    />
  );
}
