import {
  SessionTest,
  FilterOperatorType,
  ESFilter,
  ESFilterValue,
} from '@tensorleap/api-client';
import {
  BTWVisualizationFilter,
  ClusterVisualizationFilter,
  EQVisualizationFilter,
  GreaterThanVisualizationFilter,
  INVisualizationFilter,
  LessThanVisualizationFilter,
  NEVisualizationFilter,
  NotBTWVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';
import { isFilterReady } from '../core/filters';

export function getFormattedModelTestName(modelTest: SessionTest) {
  return `${
    modelTest.testFilter.field
  } is ${modelTest.testFilter.operator
    .valueOf()
    .replace('-', ' ')
    .replace('_', ' ')} ${modelTest.testFilter.value}`;
}

export function mapToEsFilters(
  visualizationFilters: VisualizationFilter[]
): ESFilter[] {
  return visualizationFilters.filter(isFilterReady).map(mapToEsFilter);
}

export function mapToEsFilter({
  field,
  operator,
  value,
  displayData,
}: VisualizationFilter): ESFilter {
  const esOperator = operator as FilterOperatorType;
  const formattedFilter: ESFilter = {
    field: field,
    operator: esOperator,
    value: mapVisualizationFilterValue(value, esOperator),
    displayData,
  };

  return formattedFilter;
}

function mapVisualizationFilterValue(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  operator: FilterOperatorType
): ESFilterValue {
  switch (operator) {
    case FilterOperatorType.Between:
      return { gt: value.gte, lt: value.lt };
    case FilterOperatorType.NotBetween:
      return { gt: value.gte, lt: value.lt };
    case FilterOperatorType.Equal:
      return { eq: value };
    case FilterOperatorType.NotEqual:
      return { eq: value };
    case FilterOperatorType.In:
    case FilterOperatorType.NotIn:
      return { lst: value };
    case FilterOperatorType.LessThan:
      return { lt: value };
    case FilterOperatorType.GreaterThan:
      return { gt: value };
    case FilterOperatorType.Cluster:
    case FilterOperatorType.NotCluster:
      return { blob_path: value.url };

    default:
      throw new Error('Unsupported filter type');
  }
}

export function mapToVisualizationFilters(
  datasetFilter: ESFilter
): VisualizationFilter {
  switch (datasetFilter.operator) {
    case FilterOperatorType.Between:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: { gte: datasetFilter.value.gt, lt: datasetFilter.value.lt },
      } as BTWVisualizationFilter;
    case FilterOperatorType.NotBetween:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: { gte: datasetFilter.value.gt, lt: datasetFilter.value.lt },
      } as NotBTWVisualizationFilter;
    case FilterOperatorType.Equal:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: datasetFilter.value.eq,
      } as EQVisualizationFilter;
    case FilterOperatorType.NotEqual:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: datasetFilter.value.eq,
      } as NEVisualizationFilter;
    case FilterOperatorType.LessThan:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: datasetFilter.value.lt,
      } as LessThanVisualizationFilter;
    case FilterOperatorType.GreaterThan:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: datasetFilter.value.gt,
      } as GreaterThanVisualizationFilter;
    case FilterOperatorType.In:
    case FilterOperatorType.NotIn:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: datasetFilter.value.lst,
      } as INVisualizationFilter;
    case FilterOperatorType.Cluster:
    case FilterOperatorType.NotCluster:
      return {
        field: datasetFilter.field,
        operator: datasetFilter.operator,
        value: {
          url: datasetFilter.value.blob_path as string,
          state: 'ready',
          origin: {
            type: 'custom',
          },
        },
        displayData: datasetFilter.displayData,
      } as ClusterVisualizationFilter;
    default:
      throw new Error('Unsupported filter type');
  }
}
