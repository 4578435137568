import { SlimVersion } from '@tensorleap/api-client';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { usePushNotifications } from '../core/PushNotificationsContext';
import { useVersionControl } from '../core/VersionControlContext';
import {
  isImportModelSuccessMsg,
  isTrainingStartedMsg,
} from '../core/websocket-message-types';
import { Chip } from '../ui/atoms/Chip';
import { Table } from '../ui/model-list/table/Table';
import { ModelFields } from '../ui/model-list/types';
import { useMultiSelector } from '../ui/model-list/selectors';
import { useIdKey } from '../ui/model-list/utils';
import { OpenIcon, Train2Icon, Trash } from '../ui/icons';
import { HoverAction } from '../ui/model-list/table/TableRowActions';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { RunModelDialog } from '../actions-dialog/RunModel';
import { ExpandedVersionRow } from './ExpandedVersionRow';
import { Tooltip } from '@material-ui/core';
import api from '../core/api-client';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import { stopPropagation } from '../core/stopPropagation';
import {
  URLS_ENUM,
  getIdFromUrl,
  removeVersionUrl,
  switchVersionUrl,
} from '../url/url-builder';
import { useHistory, useLocation } from 'react-router';
import { MiddleTruncate } from '../ui/atoms/MiddleTruncate';

type VersionsTableProps = {
  currentVersionId?: string;
  subHeader?: ReactNode;
};

export function VersionsTable({
  currentVersionId,
  subHeader,
}: VersionsTableProps) {
  const {
    versions,
    fetchVersions,
    refetch,
    deleteVersion,
  } = useVersionControl();
  const { lastServerMessage } = usePushNotifications();
  const [isCellEditing, setIsCellEditing] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const updateVersionName = useCallback(
    async (newName: string | undefined, version: SlimVersion) => {
      await api.updateVersionName({
        cid: version?.cid,
        name: newName || '',
        projectId: version.projectId,
      });
      await refetch();
    },
    [refetch]
  );

  const handleEditChange = useCallback((edit: boolean) => {
    setIsCellEditing(edit);
  }, []);

  const VERSION_FIELDS: ModelFields<SlimVersion> = useMemo(
    () => [
      {
        accessorKey: 'cid',
        label: 'cid',
        isId: true,
        hidden: true,
      },
      {
        label: 'version',
        table: { cellClassName: 'pl-0', headerClassName: 'pl-0' },
        format(version: SlimVersion) {
          return (
            <div onClick={stopPropagation}>
              <EditableInputCell
                value={version.notes}
                onChange={(value) => updateVersionName(value, version)}
                textWhenNotEditable={true}
                onEditChange={handleEditChange}
                className="w-[170px]"
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'branchName',
        label: 'branch',
        table: { align: 'center', headerClassName: 'w-24' },
        format(branchName) {
          return (
            <Tooltip arrow title={branchName} placement="left">
              <Chip
                className="whitespace-nowrap overflow-hidden text-xs justify-center text-ellipsis w-24"
                borderClassName="bg-white/10 border-gray-700"
              >
                <Tooltip arrow title={branchName}>
                  <MiddleTruncate value={branchName} />
                </Tooltip>
              </Chip>
            </Tooltip>
          );
        },
      },
    ],
    [handleEditChange, updateVersionName]
  );

  const itemIdKey = useIdKey(VERSION_FIELDS);
  const isSelected = useCallback(
    (item: SlimVersion) => item.cid === currentVersionId,
    [currentVersionId]
  );

  const versionIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.VERSION);

  const expander = useMultiSelector<SlimVersion>({
    itemIdKey,
    defaultValue: [versionIdFromUrl as string],
  });
  const hasMultiVersions = versions?.length > 1;
  const [versionToDelete, setVersionToDelete] = useState<SlimVersion | null>(
    null
  );
  const handleDeleteVersionDialogClose = useCallback(
    () => setVersionToDelete(null),
    []
  );
  const handleConfirmDeleteVersion = useCallback(async () => {
    try {
      versionToDelete && (await deleteVersion(versionToDelete.cid));
    } catch (err) {
      console.error(err);
    }
    if (versionToDelete && versionToDelete?.cid === currentVersionId) {
      history.push({
        pathname: removeVersionUrl(location.pathname),
        search: location.search,
      });
    }
    setVersionToDelete(null);
  }, [
    currentVersionId,
    deleteVersion,
    history,
    location.pathname,
    location.search,
    versionToDelete,
  ]);

  const [versionToTrain, setVersionToTrain] = useState<SlimVersion | null>(
    null
  );
  const handleTrainDialogClose = useCallback(() => setVersionToTrain(null), []);

  const hoverActions = useMemo<HoverAction<SlimVersion>[]>(
    () => [
      {
        icon: <OpenIcon />,
        title: 'Open Commit',
        onSelect: ({ cid }) =>
          history.push({
            pathname: switchVersionUrl(location.pathname, cid),
            search: location.search,
          }),
      },
      { icon: <Train2Icon />, title: 'Train', onSelect: setVersionToTrain },
      {
        icon: <Trash />,
        title: 'Delete Version',
        onSelect: setVersionToDelete,
        filter: () => hasMultiVersions,
      },
    ],

    [hasMultiVersions, history, location.pathname, location.search]
  );

  useEffect(() => {
    if (
      isTrainingStartedMsg(lastServerMessage) ||
      isImportModelSuccessMsg(lastServerMessage)
    ) {
      fetchVersions();
    }
  }, [lastServerMessage, fetchVersions]);

  return (
    <>
      <Table
        inline
        className="overflow-x-hidden"
        tableBgClass="bg-gray-800"
        headersBgClass="bg-gray-700"
        rowBorderBottomClass="border-b-gray-700"
        isSelected={isSelected}
        fields={VERSION_FIELDS}
        data={versions}
        subHeader={subHeader}
        hoverActions={hoverActions}
        showHoverActions={!isCellEditing}
        expander={expander}
        onRowClick={expander.toggle}
        ExpendRowComp={ExpandedVersionRow}
        expanderPosition="left"
      />
      {versionToTrain && (
        <RunModelDialog
          isOpen={true}
          onClose={handleTrainDialogClose}
          initialVersion={versionToTrain}
          initialRunModelMethod="Train"
        />
      )}
      <ConfirmDialog
        title="Are you sure you want to delete this version?"
        isOpen={!!versionToDelete}
        onClose={handleDeleteVersionDialogClose}
        onConfirm={handleConfirmDeleteVersion}
      />
    </>
  );
}
