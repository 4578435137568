import { Redirect, Route, useHistory, useLocation } from 'react-router';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { DatasetsProvider } from '../core/DatasetsContext';
import { DashboardContextProvider } from '../dashboard/DashboardContext';
import { DashboardsPane } from '../dashboard/DashboardPane';
import { NetworkEditorPane } from '../network-editor';
import api from '../core/api-client';
import {
  SELECTED_DASHBOARD_KEY,
  URLS_ENUM,
  buildProjectUrl,
  deleteQueryParam,
  getStringValueFromQueryParams,
  setQueryParam,
  DASHBOARD_STATE_KEY,
  STATE_KEYS,
  calcLocalStorageDashStateKey,
  calcLocalStorageLastProjectDashboardKey,
} from '../url/url-builder';
import { ProjectTabs } from './ProjectCardTabs';
import { PageLoader } from './molecules/PageLoader';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import {
  isVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface stateKeyToValue {
  stateKey: STATE_KEYS;
  value: unknown;
}

export interface HandleStateChangeProps {
  newValues: stateKeyToValue[];
  dashboardId: string;
}
export function DashboardAndNetworkTabsLoader(): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  const { search, pathname } = useLocation();
  const history = useHistory();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const dashboardIdFromUrl = getStringValueFromQueryParams(
    search,
    SELECTED_DASHBOARD_KEY
  );

  const dashstateDigestFromUrl = getStringValueFromQueryParams(
    search,
    DASHBOARD_STATE_KEY
  );

  const [globalFilters, setGlobalFilters] = useState<VisualizationFilter[]>([]);

  const [localFiltersMap, setLocalFiltersMap] = useState<
    Record<string, VisualizationFilter[]>
  >({});

  const stateDigest = useMemo(
    () => getStringValueFromQueryParams(search, DASHBOARD_STATE_KEY),
    [search]
  );

  const [parsedStateDigest, setParsedStateDigest] = useState<
    string | undefined
  >();

  const [parsedState, setParsedState] = useState<
    Partial<Record<STATE_KEYS, unknown>> | undefined
  >();

  const handleStateChange = useCallback(
    async ({ newValues, dashboardId }: HandleStateChangeProps) => {
      const newState = newValues.reduce(
        (acc, { stateKey, value }) => {
          acc[stateKey as STATE_KEYS] = value;
          return acc;
        },
        { ...parsedState }
      );

      setParsedState(newState);

      const { digest } = await api.upsertState({
        projectId: currentProjectId,
        state: JSON.stringify(newState),
      });

      const localStorageDashStateKey = calcLocalStorageDashStateKey(
        dashboardId
      );
      localStorage.setItem(localStorageDashStateKey, digest);

      history.push({
        search: setQueryParam(search, DASHBOARD_STATE_KEY, digest),
      });
    },
    [currentProjectId, history, parsedState, search]
  );

  useEffect(() => {
    if (parsedState === undefined && stateDigest) {
      api
        .getState({ projectId: currentProjectId, digest: stateDigest })
        .then(({ state }) => {
          setParsedState(JSON.parse(state));
        })
        .catch((e) => {
          console.error('failed to fetch state', e);
          setParsedState({});
        });
    }
  }, [currentProjectId, stateDigest, parsedState]);

  useEffect(() => {
    if (stateDigest === parsedStateDigest) return;

    if (!stateDigest) {
      setGlobalFilters([]);
      setLocalFiltersMap({});
      setParsedStateDigest(undefined);
      return;
    }

    api
      .getState({ projectId: currentProjectId, digest: stateDigest })
      .then(({ state }) => {
        const parsedState = JSON.parse(state);

        const globalFilters = parsedState?.[STATE_KEYS.GLOBAL_FILTERS] as
          | VisualizationFilter[]
          | undefined;
        setGlobalFilters(
          Array.isArray(globalFilters)
            ? (globalFilters.filter((f) =>
                isVisualizationFilter(f)
              ) as VisualizationFilter[])
            : []
        );

        const localFiltersMap = parsedState?.[STATE_KEYS.LOCAL_FILTERS_MAP] as
          | Record<string, VisualizationFilter[]>
          | undefined;
        setLocalFiltersMap(
          localFiltersMap && typeof localFiltersMap === 'object'
            ? localFiltersMap
            : {}
        );
        setParsedStateDigest(stateDigest);
      });
  }, [currentProjectId, parsedStateDigest, search, stateDigest]);

  const { dashboards, isLoading } = useFetchDashboards({
    projectId: currentProjectId,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  const lastDashboardIdFromLocalStorage = localStorage.getItem(
    calcLocalStorageLastProjectDashboardKey(currentProjectId)
  );

  if (dashboardIdFromUrl === undefined && dashboards.length) {
    const dashboard =
      dashboards.find(({ cid }) => cid === lastDashboardIdFromLocalStorage) ||
      dashboards[0];

    const newSearch = setQueryParam(
      search,
      SELECTED_DASHBOARD_KEY,
      dashboard.cid
    );

    return (
      <Redirect
        to={{
          pathname,
          search: newSearch,
        }}
      />
    );
  }

  if (
    dashboardIdFromUrl !== undefined &&
    !dashboards.some((d) => d.cid === dashboardIdFromUrl)
  ) {
    const newSearch = deleteQueryParam(
      deleteQueryParam(search, SELECTED_DASHBOARD_KEY),
      DASHBOARD_STATE_KEY
    );
    console.warn('Dashboard not found, redirecting...', newSearch);
    return (
      <Redirect
        to={{
          pathname,
          search: newSearch,
        }}
      />
    );
  }

  if (
    dashboardIdFromUrl !== undefined && //at this point, if dashboardIdFromUrl is defined so its a validated and existing dashboard
    dashstateDigestFromUrl === undefined
  ) {
    const localStorageDashboardKey = calcLocalStorageLastProjectDashboardKey(
      currentProjectId
    );
    localStorage.setItem(localStorageDashboardKey, dashboardIdFromUrl);

    const localStorageDashStateKey = calcLocalStorageDashStateKey(
      dashboardIdFromUrl
    );

    const dashstateDigest = localStorage.getItem(localStorageDashStateKey);
    if (dashstateDigest) {
      const newSearch = setQueryParam(
        search,
        DASHBOARD_STATE_KEY,
        dashstateDigest
      );
      return (
        <Redirect
          to={{
            pathname,
            search: newSearch,
          }}
        />
      );
    }
  }

  return (
    <DashboardAndNetworkTabsContent
      dashboardId={dashboardIdFromUrl}
      globalFilters={globalFilters}
      localFiltersMap={localFiltersMap}
      handleStateChange={handleStateChange}
      key={dashboardIdFromUrl}
    />
  );
}

export type LocalFiltersMap = Record<string, VisualizationFilter[]>;

export interface DashboardAndNetworkTabsContentProps {
  dashboardId?: string;
  globalFilters: VisualizationFilter[];
  localFiltersMap: LocalFiltersMap;
  handleStateChange: (_: HandleStateChangeProps) => Promise<void>;
}

export function DashboardAndNetworkTabsContent({
  dashboardId,
  globalFilters,
  localFiltersMap,
  handleStateChange,
}: DashboardAndNetworkTabsContentProps): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const projectUrl = buildProjectUrl(currentProjectId);

  return (
    <DashboardContextProvider
      dashboardId={dashboardId}
      globalFilters={globalFilters}
      localFiltersMap={localFiltersMap}
      handleStateChange={handleStateChange}
      key={dashboardId}
    >
      <Route path={`${projectUrl}/*${URLS_ENUM.TAB}/${ProjectTabs.Dashboard}`}>
        <DashboardsPane />
      </Route>
      <Route path={[`${projectUrl}/*${URLS_ENUM.TAB}/${ProjectTabs.Network}`]}>
        <DatasetsProvider>
          <NetworkEditorPane />
        </DatasetsProvider>
      </Route>
    </DashboardContextProvider>
  );
}
