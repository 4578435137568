import { Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useMounted } from '../../core/useMounted';
import { Check1, ContentCopy1, XClose } from '../icons';

export interface CopyButtonProps {
  textToCopy: string;
  className?: string;
  afterClickedClassName?: string;
}

export function CopyButton({
  textToCopy,
  className = '',
  afterClickedClassName,
}: CopyButtonProps): JSX.Element {
  const [isShowingCopiedIcon, setIsShowingCopiedIcon] = useState(false);
  const [isShowingErrorIcon, setIsShowingErrorIcon] = useState(false);
  const isMounted = useMounted();

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      const clipboardText = await navigator.clipboard.readText();

      if (clipboardText !== textToCopy) {
        throw new Error('Text not copied correctly');
      }
      setIsShowingCopiedIcon(true);
    } catch (error) {
      setIsShowingErrorIcon(true);
      console.error(error);
    } finally {
      setTimeout(() => {
        if (isMounted) {
          setIsShowingCopiedIcon(false);
          setIsShowingErrorIcon(false);
        }
      }, 2000);
    }
  }, [isMounted, textToCopy]);

  return (
    <Tooltip
      title={
        isShowingCopiedIcon
          ? 'Copied!'
          : isShowingErrorIcon
          ? 'Something went wrong'
          : 'Copy'
      }
    >
      <Button
        className={clsx(
          className,
          isShowingCopiedIcon && afterClickedClassName
        )}
        onClick={handleCopyClick}
      >
        {isShowingCopiedIcon ? (
          <Check1 className="text-base text-secondary-500" />
        ) : isShowingErrorIcon ? (
          <XClose className="text-base text-error-500" />
        ) : (
          <ContentCopy1 className="text-base" />
        )}
      </Button>
    </Tooltip>
  );
}
