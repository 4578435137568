import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLastSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M10 3h1v8h-1V3ZM5.293 9.293 7.086 7.5H2v-1h5.086L5.293 4.707 6 4l3 3-3 3-.707-.707Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLastSmallIcon;
