import { useState, useMemo, useCallback } from 'react';
import {
  ClusterVisualizationFilter,
  VisualizationFilter,
} from '../../core/types/filters';
import { useFetchDashboardFilterFieldsMeta } from '../../core/data-fetching/dashlet-fields';
import { FilterFieldMeta } from '../../filters/helpers';
import { useDashboardContext } from '../DashboardContext';
import { useMounted } from '../../core/useMounted';
import {
  FetchSimilarRequestParams,
  FilterOperatorType,
  FilterSessionRun,
} from '@tensorleap/api-client';
import api from '../../core/api-client';
import { useMergedObject } from '../../core/useMergedObject';
import { calcHash } from '../../core/calc-hash';
import { getFilterText } from '../../filters/FilterElements';
import { trimStorageUrlProject } from '../../core/useProjectStorage';
import { useModelFilter } from '../../ui/molecules/useModelFilter';
import { useDebounce } from '../../core/useDebounce';
import { isEqual } from 'lodash';

export interface DashboardFiltersParams {
  projectId: string;
  dashletId: string;
  pinFilters?: VisualizationFilter[];
  updatePinFilters: (f: VisualizationFilter[]) => Promise<void>;
  localFilters: VisualizationFilter[];
}

type FetchParams = Omit<FetchSimilarRequestParams, 'digest'> & {
  fetchSimilarFilters: VisualizationFilter[];
  sessionRun: FilterSessionRun;
};

export interface DashboardFiltersResponse {
  filterFieldsMeta: FilterFieldMeta[];
  dashletFilters: VisualizationFilter[];
  dashletAndGlobalFilters: VisualizationFilter[];
  createClusterFilterByFetchSimilar: (props: FetchParams) => void;
  updateDashletFilters: (f: VisualizationFilter[]) => Promise<void>;
}

export function useDashletFilters({
  projectId,
  dashletId,
  pinFilters = [],
  updatePinFilters,
  localFilters,
}: DashboardFiltersParams): DashboardFiltersResponse {
  const isMounted = useMounted();

  const [updatingPinFilters, setUpdatingPinFilters] = useState<
    null | VisualizationFilter[]
  >(null);

  const {
    globalFilters,
    handleGlobalFiltersChange,
    handleLocalFiltersChange,
  } = useDashboardContext();
  const allFilters = useMemo(() => [...globalFilters, ...localFilters], [
    globalFilters,
    localFilters,
  ]);
  const { selected } = useModelFilter();
  const selectedSessionRunIds = useMemo(() => selected.map((s) => s.id), [
    selected,
  ]);
  const filterFieldsMeta = useFetchDashboardFilterFieldsMeta({
    projectId,
    filters: allFilters,
    sessionRunIds: selectedSessionRunIds,
  });

  const addLocalFilter = useCallback(
    (filter: VisualizationFilter) => {
      const filterExists = localFilters.some((currFilter) =>
        isEqual(currFilter, filter)
      );
      const newFilters = filterExists
        ? localFilters
        : [...localFilters, filter];
      handleLocalFiltersChange(dashletId, newFilters);
    },
    [localFilters, handleLocalFiltersChange, dashletId]
  );

  const dashletFilters = useMemo(
    () => [...(updatingPinFilters || pinFilters), ...localFilters],
    [updatingPinFilters, pinFilters, localFilters]
  );

  const dashletAndGlobalFilters = useMemo(
    () => [...dashletFilters, ...globalFilters],
    [dashletFilters, globalFilters]
  );

  const updateDashletFilters = useCallback(
    async (filters: VisualizationFilter[]) => {
      const newPinFilters = filters.filter((f) => f.pin);
      const newNonPinFilters = filters.filter((f) => !f.pin);

      const isPinChange =
        JSON.stringify(newPinFilters) !== JSON.stringify(pinFilters);

      isPinChange && setUpdatingPinFilters(newPinFilters);
      handleLocalFiltersChange(dashletId, newNonPinFilters);

      if (isPinChange) {
        await updatePinFilters(newPinFilters);
        setUpdatingPinFilters(null);
      }
    },
    [pinFilters, handleLocalFiltersChange, dashletId, updatePinFilters]
  );

  const triggerOrCheck = useDebounce(
    async (
      newFilter: ClusterVisualizationFilter,
      fetchParams: FetchParams & { digest: string },
      trigger = false
    ) => {
      if (!isMounted.current) {
        return;
      }

      const result = await (trigger
        ? api.fetchSimilar(fetchParams)
        : api.getFetchSimilarStatus(fetchParams));

      if (!isMounted.current) {
        return;
      }

      if (result.status === 'FAILED') {
        const removeFunc = (curr: VisualizationFilter[]) =>
          curr.some((filter) => filter === newFilter)
            ? curr.filter((filter) => filter !== newFilter)
            : curr;

        handleLocalFiltersChange(dashletId, removeFunc(localFilters));

        handleGlobalFiltersChange(
          globalFilters.some((filter) => filter === newFilter)
            ? globalFilters.filter((filter) => filter !== newFilter)
            : globalFilters
        );
        return;
      } else if (
        result.status !== 'FINISHED' ||
        result.readyArtifacts.clusterPath === undefined
      ) {
        setTimeout(() => triggerOrCheck(newFilter, fetchParams, true), 5000);
        return;
      }

      const url = trimStorageUrlProject(result.readyArtifacts.clusterPath);

      const updatedFilter: ClusterVisualizationFilter = {
        ...newFilter,
        value: {
          ...newFilter.value,
          url,
          state: 'ready',
        },
      };

      if (localFilters.some((filter) => isEqual(filter, newFilter))) {
        handleLocalFiltersChange(
          dashletId,
          localFilters.map((filter) =>
            isEqual(filter, newFilter) ? updatedFilter : filter
          )
        );
      } else if (globalFilters.some((filter) => isEqual(filter, newFilter))) {
        handleGlobalFiltersChange(
          globalFilters.map((filter) =>
            isEqual(filter, newFilter) ? updatedFilter : filter
          )
        );
      }
    },
    0
  );

  const createClusterFilterByFetchSimilar = useCallback(
    (props: FetchParams) => {
      const digest = calcHash(props);
      const fetchParams = { ...props, digest };

      const {
        limit,
        sampleIds,
        epoch,
        fetchSimilarFilters,
        sessionRun,
      } = props;

      const newFilter: ClusterVisualizationFilter = {
        field: FilterOperatorType.Cluster,
        operator: FilterOperatorType.Cluster,
        value: {
          url: 'calculating...',
          state: 'calculating',
        },
        displayData: {
          type: 'fetch-similar',
          limit,
          sampleIds,
          epoch,
          sessionRun,
          filtersUsed: fetchSimilarFilters
            .filter((f) => !f.disable)
            .map((f) => getFilterText(f, true)),
        },
      };

      addLocalFilter(newFilter);

      triggerOrCheck(newFilter, fetchParams, true);
    },
    [addLocalFilter, triggerOrCheck]
  );

  return useMergedObject({
    filterFieldsMeta,
    dashletFilters,
    dashletAndGlobalFilters,
    createClusterFilterByFetchSimilar,
    updateDashletFilters,
  });
}
