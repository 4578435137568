import {
  FetchSimilarFilterDisplayData,
  FilterDisplayData,
  FilterOperatorType,
  InsightFilterDisplayData,
} from '@tensorleap/api-client';

export function isInsightDisplayData(
  obj: unknown
): obj is InsightFilterDisplayData {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (obj as FilterDisplayData).type === 'insight'
  );
}

export function isFetchSimilarDisplayData(
  obj: unknown
): obj is FetchSimilarFilterDisplayData {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (obj as FilterDisplayData).type === 'fetch-similar'
  );
}

interface GenericVisualizationFilter<Op extends FilterOperatorType, V> {
  field: string;
  operator: Op;
  value: V;
  disable?: boolean;
  pin?: boolean;
  displayData?: FilterDisplayData;
}

export type EQVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.Equal,
  string | number
>;
export type NEVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.NotEqual,
  string | number
>;
export type INVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.In,
  string[] | number[]
>;
export type NINVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.NotIn,
  string[] | number[]
>;
export type BTWVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.Between,
  { gte?: number; lt?: number }
>;
export type NotBTWVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.NotBetween,
  { gte?: number; lt?: number }
>;

type ClusterState = 'calculating' | 'ready' | 're-calculating';

export type Cluster = {
  url: string;
  state: ClusterState;
};

export type ClusterVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.Cluster,
  Cluster
>;

export type NClusterVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.NotCluster,
  Cluster
>;

export type LessThanVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.LessThan,
  number
>;

export type GreaterThanVisualizationFilter = GenericVisualizationFilter<
  FilterOperatorType.GreaterThan,
  number
>;

export type VisualizationFilter =
  | EQVisualizationFilter
  | NEVisualizationFilter
  | INVisualizationFilter
  | BTWVisualizationFilter
  | NotBTWVisualizationFilter
  | ClusterVisualizationFilter
  | NClusterVisualizationFilter
  | NINVisualizationFilter
  | LessThanVisualizationFilter
  | GreaterThanVisualizationFilter;

export function isVisualizationFilter(
  obj: unknown
): obj is VisualizationFilter {
  const maybeFilter = obj as VisualizationFilter | undefined;
  return (
    maybeFilter?.field !== undefined &&
    maybeFilter?.operator !== undefined &&
    maybeFilter?.value !== undefined
  );
}
