import { isObject } from 'lodash';
import { ChangeEventHandler, useState } from 'react';
import { FormEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import {
  VisualizationFilter,
  INVisualizationFilter,
  ClusterVisualizationFilter,
} from '../core/types/filters';
import { Input } from '../ui/atoms/Input';
import { Select } from '../ui/atoms/Select';
import { TextArea } from '../ui/atoms/TextArea';
import {
  BetweenOrNotBetweenFilterType,
  EqualOrNotEqualFilterType,
  FilterFieldMeta,
  isNotEmptyString,
  isNumericArray,
  LessOrGreaterThanFilterType,
} from './helpers';
import { isStringOrNumber } from './utils';
import { SelectMultiple } from '../ui/atoms/SelectMultiple';
import { Checkbox } from '@material-ui/core';
import { useToggle } from '../core/useToggle';

type ValueInputProps<Filter extends VisualizationFilter> = {
  fieldMeta: FilterFieldMeta;
  form: UseFormReturn<Filter>;
  className?: string;
};

export function EqualOrNotEqualValueInput({
  fieldMeta,
  form,
  className,
}: ValueInputProps<EqualOrNotEqualFilterType>) {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const { field: valueField } = useController({
    control,
    rules: { required: `*Required` },
    name: 'value',
    shouldUnregister: true,
  });

  const value = watch('value');

  return (
    <StringOrNumberValue
      type={fieldMeta.type}
      enum={fieldMeta.enum}
      className={className}
      label="Value"
      required
      error={errors?.value?.message}
      value={value}
      onChange={valueField.onChange}
    />
  );
}

export function LessOrGreaterThanValueInput({
  form,
  className,
}: ValueInputProps<LessOrGreaterThanFilterType>) {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const { field: valueField } = useController({
    control,
    rules: { required: `*Required` },
    name: 'value',
    shouldUnregister: true,
  });

  const value = watch('value');

  return (
    <StringOrNumberValue
      type="number"
      className={className}
      label="Value"
      required
      error={errors?.value?.message}
      value={value}
      onChange={valueField.onChange}
    />
  );
}

const RANGE_ERROR_MESSAGE = `Please specify 'greater than' and 'less than' values`;
const RANGE_REVERSE_VALUES_ERROR_MESSAGE = `Value 'greater than' cannot be greater than value 'less than'`;
export function BetweenOrNotBetweenValueInput({
  fieldMeta,
  form,
}: ValueInputProps<BetweenOrNotBetweenFilterType>) {
  const { control, watch } = form;

  const ltValueRef = useRef<number | undefined>(undefined);
  // the useForm errors not update well in this case
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { field: value } = useController({
    control,
    name: 'value',
  });

  const { field: gteField } = useController({
    control,
    rules: {
      validate: (gteValue) => {
        if (gteValue === undefined || ltValueRef.current === undefined) {
          setErrorMessage(RANGE_ERROR_MESSAGE);
          return RANGE_ERROR_MESSAGE;
        } else if (gteValue > ltValueRef.current) {
          setErrorMessage(RANGE_REVERSE_VALUES_ERROR_MESSAGE);
          return RANGE_REVERSE_VALUES_ERROR_MESSAGE;
        }
        setErrorMessage(undefined);
        return undefined;
      },
      deps: 'value.lt',
    },
    name: 'value.gte',
    shouldUnregister: true,
  });

  const { field: ltField } = useController({
    control,
    name: 'value.lt',
  });

  const gteValue = watch('value.gte');
  const ltValue = watch('value.lt');
  ltValueRef.current = ltValue;

  useEffect(() => {
    if (!isObject(value.value)) {
      value.onChange({});
    }
  }, [value]);

  return (
    <>
      <StringOrNumberValue
        type={fieldMeta.type}
        label="greater than"
        error={errorMessage}
        value={gteValue}
        onChange={gteField.onChange}
      />
      <StringOrNumberValue
        type={fieldMeta.type}
        label="less than"
        error={errorMessage ? ' ' : ''}
        value={ltValue}
        onChange={ltField.onChange}
      />
    </>
  );
}

export function InValueInput({
  form,
  fieldMeta: { type, enum: options },
}: ValueInputProps<INVisualizationFilter>) {
  const { control, watch } = form;

  // the useForm errors not update well in this case
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { field: valueField } = useController({
    control,
    rules: {
      validate: (value) => {
        let msg: string | undefined = undefined;
        const isEmpty =
          !Array.isArray(value) ||
          !(value as unknown[]).filter(isNotEmptyString).length;

        if (isEmpty) {
          msg = '* Required';
        } else if (
          Array.isArray(value) &&
          type === 'number' &&
          !isNumericArray(value)
        ) {
          msg = '* Values must be numbers';
        }

        setErrorMessage(msg);

        return msg;
      },
    },
    name: 'value',
    defaultValue: [],
    shouldUnregister: true,
  });
  const value = watch('value');

  const handleChange = useCallback(
    (e: FormEvent<HTMLTextAreaElement>) => {
      valueField.onChange((e.target as HTMLTextAreaElement).value.split('\n'));
    },
    [valueField]
  );

  const stringifyOptions = useMemo(() => {
    if (Array.isArray(options)) return options.map(String);
    if (typeof options === 'function')
      return (q: string) => options(q).then((os) => os.map(String));
    return [];
  }, [options]);

  const [asText, toggleAsText] = useToggle(false);

  useEffect(() => {
    if (Array.isArray(value)) return;
    const defaultValue = isStringOrNumber(value) ? [String(value)] : [];
    valueField.onChange(defaultValue);
  }, [valueField, value]);

  return (
    <div className="col-span-full flex flex-col">
      {asText ? (
        <TextArea
          required
          className="col-span-full pb-2 h-24 text-sm"
          label="values (split by newline)"
          error={errorMessage}
          onChange={handleChange}
          value={Array.isArray(value) ? value.join('\n') : ''}
        />
      ) : (
        <SelectMultiple
          label="values"
          options={stringifyOptions}
          queryToOption={(q) => q}
          error={errorMessage}
          value={value}
          onChange={valueField.onChange}
        />
      )}
      <label className="text-sm font-semibold self-end text-gray-600">
        <Checkbox value={asText} onClick={toggleAsText} />
        value as text
      </label>
    </div>
  );
}

type StringOrNumber = string | number;
type StringOrNumberValueProps = Pick<FilterFieldMeta, 'enum' | 'type'> & {
  label: string;
  value?: StringOrNumber;
  onChange: (value?: StringOrNumber) => void;
  error?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
};

function StringOrNumberValue({
  label,
  onChange,
  enum: options,
  type,
  className,
  value,
  required = false,
  error,
  disabled = false,
}: StringOrNumberValueProps) {
  const hasOptions = !!options;

  const stringifyOptions = useMemo(() => {
    if (Array.isArray(options)) return options.map(String);
    if (typeof options === 'function')
      return (q: string) => options(q).then((os) => os.map(String));
    return [];
  }, [options]);

  const handleChange = useCallback(
    (value?: StringOrNumber) => {
      if (type === 'string') return onChange(value);
      else if (value === '' || value === undefined) return onChange(undefined);
      const asNumber = Number(value);
      if (!isNaN(asNumber)) return onChange(asNumber);
    },
    [type, onChange]
  );

  useEffect(() => {
    if (value === undefined) return;

    if (!isStringOrNumber(value)) {
      onChange(undefined);
      return;
    }

    const isTypeNumber = type === 'number';
    const mappedValue = isTypeNumber ? Number(value) : String(value);

    const isTypeNumberAndNaN = isTypeNumber && isNaN(mappedValue as number);
    if (value !== mappedValue && !isTypeNumberAndNaN) {
      onChange(mappedValue);
    }
  }, [options, value, onChange, type]);

  const valueAsString = useMemo(() => String(value ?? ''), [value]);

  return hasOptions ? (
    <Select
      disabled={disabled}
      className={className}
      label={label}
      options={stringifyOptions}
      value={valueAsString}
      onChange={handleChange}
      required={required}
      error={error}
      queryToOption={(q) => q}
    />
  ) : (
    <InputWithLocalValue
      disabled={disabled}
      className={className}
      required
      label={label}
      type={type}
      error={error}
      value={valueAsString}
      onChange={handleChange}
    />
  );
}

type InputWithLocalValueProps = StringOrNumberValueProps;

function InputWithLocalValue({
  className,
  label,
  type,
  required,
  error,
  value,
  onChange,
  disabled,
}: InputWithLocalValueProps) {
  const [localValue, setLocalValue] = useState(value);

  const [isLocal, setIsLocal] = useState(false);
  const handleFocus = useCallback(() => setIsLocal(true), []);
  const handleBlur = useCallback(() => setIsLocal(false), []);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const inputValue = event.target.value;
      onChange(inputValue);
      setLocalValue(inputValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (!isLocal) setLocalValue(value);
  }, [isLocal, value]);

  return (
    <Input
      containerProps={{ className }}
      label={label}
      step={type === 'number' ? 'any' : undefined}
      required={required}
      type={type === 'number' ? 'number' : 'text'}
      error={error}
      value={localValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleInputChange}
      disabled={disabled}
    />
  );
}

export function ClusterValueInput({
  fieldMeta,
  form,
  className,
}: ValueInputProps<ClusterVisualizationFilter>) {
  const { control, watch } = form;

  const { field: valueField } = useController({
    control,
    rules: { required: `*Required` },
    name: 'value.url',
    shouldUnregister: true,
  });

  const value = watch('value.url');

  return (
    <>
      <StringOrNumberValue
        type={fieldMeta.type}
        enum={fieldMeta.enum}
        className={className}
        label="Value"
        required
        value={value}
        onChange={valueField.onChange}
        disabled
      />
    </>
  );
}
