import {
  SampleIdentity,
  ScatterViz,
  VisualizationResponse,
} from '@tensorleap/api-client';
import { PreviewContainer } from '../common/PreviewsContainer';
import { SelectedSessionRun } from '../../../ui/molecules/useModelFilter';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Refresh2Icon, VisualizeIcon } from '../../../ui/icons';
import clsx from 'clsx';
import { compactNumberFormatter } from '../../../core/formatters/number-formatting';
import { Popover, Tooltip } from '@material-ui/core';
import { VisualizationRunningPlaceholder } from '../common/Placeholders';
import api from '../../../core/api-client';
import { usePopulationExploration } from './usePopulationExploration';
import { ScatterLoader } from './ScatterLoader';
import { useFetchScatterVisualizationsMap } from '../../../core/data-fetching/scatter-visualizations-map';
import { NoDataChart } from '../../../ui/charts/common/NoDataChart';
import { useDashletScatterContext } from './DashletScatterContext';
import { MousePosition } from '../../../core/useSelectionGroup';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import { Button } from '../../../ui/atoms/Button';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import { ConfirmDialog } from '../../../ui/atoms/DeleteContentDialog';

interface ScatterAnalyzerViewProps {
  sessionRun: SelectedSessionRun;
  dashletId: string;
  mousePosition: MousePosition;
  className?: string;
}

export function ScatterAnalyzerView({
  sessionRun,
  className,
  dashletId,
  mousePosition,
}: ScatterAnalyzerViewProps): JSX.Element {
  const {
    projectId,
    settings: { projectionMetric, displayParams },
    filters: { dashletAndGlobalFilters: localAndGlobalFilters },
  } = useDashletScatterContext();

  const {
    fullVisualization,
    loadingStatus,
    lastReadyDigest,
  } = usePopulationExploration({
    projectId,
    sessionRunId: sessionRun.id,
    filters: localAndGlobalFilters,
    dashletId,
    projectionMetric,
    displayParams,
    epoch: sessionRun.epochsState.selectedEpoch,
  });

  const headerLoadingType =
    loadingStatus === 'updating' || loadingStatus === 'refreshing'
      ? loadingStatus
      : undefined;

  return (
    <PreviewContainer
      flex
      className={className}
      sessionRun={sessionRun}
      loadingType={headerLoadingType}
      header={
        <div className="flex flex-row h-full gap-2 items-center relative justify-center w-fit">
          {fullVisualization && (
            <CreateVisualizationsButton
              sessionRun={sessionRun}
              epoch={sessionRun.epochsState.selectedEpoch}
              projectId={projectId}
              scatterVisualization={fullVisualization}
              digest={lastReadyDigest}
            />
          )}
        </div>
      }
    >
      {fullVisualization ? (
        <ScatterLoader
          projectId={projectId}
          epoch={sessionRun.epochsState.selectedEpoch}
          sessionRunId={sessionRun.id}
          scatterVisualization={fullVisualization}
          mousePosition={mousePosition}
        />
      ) : loadingStatus === 'loading' ? (
        <VisualizationRunningPlaceholder
          processName="processing..."
          tourId={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_PROCESSING_ID}
        />
      ) : (
        <NoDataChart />
      )}
    </PreviewContainer>
  );
}

interface CreateVisualizationsButtonProps {
  projectId: string;
  sessionRun: SelectedSessionRun;
  epoch: number;
  scatterVisualization?: VisualizationResponse;
  digest?: string;
}
function CreateVisualizationsButton({
  projectId,
  epoch,
  sessionRun,
  scatterVisualization,
  digest,
}: CreateVisualizationsButtonProps): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'createVisualizationPopover',
    disableAutoFocus: false,
  });

  const { scatterVisualizationsMapResponse } = useFetchScatterVisualizationsMap(
    projectId,
    sessionRun.id,
    epoch
  );

  const allSampleIds = useMemo(
    () =>
      (scatterVisualization?.data.payload[0] as ScatterViz)?.scatter_data
        ?.samples || [],
    [scatterVisualization?.data.payload]
  );

  const samplesToVisualize = useMemo(() => {
    const samplesIds = new Set(scatterVisualizationsMapResponse?.samplesIds);

    return allSampleIds.filter(
      ({ index, state }) => !samplesIds.has(`${state}_${index}`)
    );
  }, [allSampleIds, scatterVisualizationsMapResponse?.samplesIds]);

  const visualizeHeader = useMemo(() => {
    return samplesToVisualize.length
      ? `Visualize ${compactNumberFormatter.format(
          samplesToVisualize.length
        )} samples`
      : '';
  }, [samplesToVisualize.length]);

  const generateScatterImages = useCallback(async () => {
    if (!digest) return;

    const hasVisualizations =
      (scatterVisualizationsMapResponse?.samplesIds || []).length > 0;

    if (hasVisualizations) {
      popoverState.open();
      return;
    }
    await api.createSamplesVisualizations({
      projectId,
      sessionRunId: sessionRun.id,
      epoch,
      sampleIdentities: samplesToVisualize,
      digest,
    });
  }, [
    digest,
    epoch,
    popoverState,
    projectId,
    samplesToVisualize,
    scatterVisualizationsMapResponse?.samplesIds,
    sessionRun.id,
  ]);

  return (
    <>
      <Tooltip title={visualizeHeader}>
        <Button
          className={clsx(
            'flex uppercase text-xs',
            samplesToVisualize.length && 'text-warning-300'
          )}
          {...bindTrigger(popoverState)}
          ref={ref}
          variant="text"
          onClick={generateScatterImages}
          disabled={!digest}
          tourId={
            TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_VISUALIZE_BUTTON_ID
          }
        >
          <span className="mr-1">Visualize</span>
          <VisualizeIcon />
        </Button>
      </Tooltip>
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={ref.current}
        classes={{
          paper: 'bg-gray-850 border border-gray-700',
        }}
      >
        <RefreshScatterVisualizationsDialog
          onClose={popoverState.close}
          digest={digest || ''}
          epoch={epoch}
          projectId={projectId}
          sessionRunId={sessionRun.id}
          sampleToVisualize={samplesToVisualize}
          allSampleIdentities={allSampleIds}
        />
      </Popover>
    </>
  );
}

interface RefreshScatterVisualizationsDialogProps {
  onClose: () => void;
  projectId: string;
  sessionRunId: string;
  epoch: number;
  allSampleIdentities: Array<SampleIdentity>;
  sampleToVisualize: Array<SampleIdentity>;
  digest: string;
}
function RefreshScatterVisualizationsDialog({
  onClose,
  projectId,
  sessionRunId,
  epoch,
  allSampleIdentities,
  sampleToVisualize,
  digest,
}: RefreshScatterVisualizationsDialogProps): JSX.Element {
  const handleCreateSamplesVisualizations = useCallback(
    async (refresh: boolean) => {
      await api.createSamplesVisualizations({
        projectId,
        sessionRunId,
        epoch,
        sampleIdentities: refresh ? allSampleIdentities : sampleToVisualize,
        digest,
        refresh,
      });
      onClose();
    },
    [
      projectId,
      sessionRunId,
      epoch,
      sampleToVisualize,
      allSampleIdentities,
      digest,
      onClose,
    ]
  );

  const [isRevisualizeDialogOpen, setIsRevisualizeDialogOpen] = useState(false);

  const openRevisualizeDialog = useCallback(
    () => setIsRevisualizeDialogOpen(true),
    []
  );

  const handleCloseRevisualizeDialog = useCallback(
    () => setIsRevisualizeDialogOpen(false),
    []
  );

  const handleConfirmRevisualizeDialog = useCallback(() => {
    handleCreateSamplesVisualizations(true);
    handleCloseRevisualizeDialog();
  }, [handleCreateSamplesVisualizations, handleCloseRevisualizeDialog]);

  return (
    <>
      <div className="flex flex-col gap-4 p-2">
        {sampleToVisualize.length > 0 && (
          <Button
            variant="outline"
            onClick={() => handleCreateSamplesVisualizations(false)}
          >
            Visualize rest
          </Button>
        )}
        <Button variant="outline" onClick={openRevisualizeDialog}>
          Revisualize all
        </Button>
      </div>

      <ConfirmDialog
        title="This action will delete all existing visualizations. Proceeding will initiate a new visualization process for the selected samples. Are you sure you want to continue?"
        isOpen={isRevisualizeDialogOpen}
        onClose={handleCloseRevisualizeDialog}
        onConfirm={handleConfirmRevisualizeDialog}
        confirmButtonText="Continue"
        confirmButtonIcon={<Refresh2Icon />}
      />
    </>
  );
}
