import { NumberOrString } from '@tensorleap/api-client';
import { LabelsLegend } from '../../ui/charts/legend/LabelsLegend';
import { useLabelsLegendData } from '../useLabelsLegendData';

import { useScatterData } from '../ScatterDataContext';
import { ScatterLegendProps } from './ShapeLegend';
import { useCallback } from 'react';

export function LabelsScatterLegend({
  onLegendClick,
}: ScatterLegendProps): JSX.Element {
  const labelsAppearances = useLabelsLegendData();

  const {
    settings: { dotColor },
    miByCluster,
    showLegendNames: showNames,
    toggleShowLegendNames: toggleShowNames,
    legendTruncatedLongtail: truncatedLongtail,
    setLegendTruncatedLongtail: setTruncatedLongtail,
    setLegendHovered,
  } = useScatterData();

  const _onLegendClick = useCallback(
    (value: NumberOrString | NumberOrString[]) => {
      dotColor && onLegendClick(dotColor, value);
    },
    [dotColor, onLegendClick]
  );

  const clusterData =
    dotColor && miByCluster ? miByCluster[dotColor] : undefined;

  return (
    <LabelsLegend
      dataKey={dotColor}
      setHoverFilter={setLegendHovered}
      truncatedLongtail={truncatedLongtail}
      setTruncatedLongtail={setTruncatedLongtail}
      showNames={showNames}
      toggleShowNames={toggleShowNames}
      labels={Array.from(labelsAppearances.keys())}
      appearances={labelsAppearances}
      onLegendClick={_onLegendClick}
      innerSplit={null}
      clusterData={clusterData}
    />
  );
}
