import { useRef } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '../ui/mui';
import { PushpinOnIcon, PushpinOffIcon } from '../ui/icons';
import { VersionsTable } from './VersionsTable';
import { VersionControlActions } from './VersionControlActions';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { MiddleTruncate } from '../ui/atoms/MiddleTruncate';

export type ShelveAction = 'SaveCommit' | 'ImportModel';

export interface VersionControlPaneProps {
  projectName: string;
  className?: string;
  currentVersionNotes?: string;
  currentVersionId?: string;
  shelveAction?: ShelveAction;
  togglePin: () => void;
  pinned: boolean;
  onActionCancelled: () => void;
}
export function VersionControlPane({
  projectName,
  className,
  currentVersionNotes: currentVersionName,
  currentVersionId,
  shelveAction,
  pinned,
  togglePin,
  onActionCancelled,
}: VersionControlPaneProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      id={TOUR_SELECTORS_ENUM.VERSION_CONTROL_PANE_ID}
      className={clsx(
        'flex flex-col w-80 overflow-hidden max-h-full',
        className
      )}
    >
      <div className={clsx('flex justify-between items-center p-4')}>
        <div className="overflow-hidden">
          <Tooltip title={projectName} arrow>
            <h6 className="font-medium text-xl leading-normal whitespace-nowrap ">
              <MiddleTruncate value={projectName} />
            </h6>
          </Tooltip>
          <Tooltip title={currentVersionName || ''} arrow>
            <span className="font-normal text-base tracking-normal whitespace-nowrap">
              <MiddleTruncate value={currentVersionName || ''} />
            </span>
          </Tooltip>
        </div>
        <IconButton onClick={togglePin}>
          {pinned ? (
            <PushpinOnIcon className="rounded-full bg-primary-900 border border-gray-700" />
          ) : (
            <PushpinOffIcon />
          )}
        </IconButton>
      </div>

      <VersionsTable
        currentVersionId={currentVersionId}
        subHeader={
          <VersionControlActions
            shelveAction={shelveAction}
            onActionCancelled={onActionCancelled}
          />
        }
      />
    </div>
  );
}
