import {
  MutualInformationElement,
  NumberOrString,
} from '@tensorleap/api-client';
import { labelsColorSupplier } from '../../../core/color-helper';
import { LegendItem } from './LegendItem';

export interface ScatterLabelElementProps {
  label: NumberOrString;
  value: NumberOrString;
  handleLegendClick: (label: NumberOrString, value: NumberOrString) => void;
  handleLegendMouseOver: (label: NumberOrString, value: NumberOrString) => void;
  handleLegendMouseLeave: () => void;
  showNames: boolean;
  hiddenLabels?: NumberOrString[];
  truncatedLongtail: number;
  appearances?: Map<NumberOrString, number>;
  clusterData?: Record<string, MutualInformationElement[]>;
  colorMap?: Record<string, string>;
}

export function ScatterLabelElement({
  label,
  value,
  handleLegendClick,
  handleLegendMouseOver,
  handleLegendMouseLeave,
  showNames,
  hiddenLabels,
  truncatedLongtail,
  appearances,
  clusterData,
  colorMap,
}: ScatterLabelElementProps): JSX.Element {
  const isHidden = hiddenLabels?.includes(value);
  const color = colorMap?.[label] ?? labelsColorSupplier.get(String(label));

  const _handleLegendClick = (value: NumberOrString) => {
    handleLegendClick?.(label, value);
  };

  const _handleLegendMouseOver = (value: NumberOrString) => {
    handleLegendMouseOver?.(label, value);
  };

  return (
    <LegendItem
      label={label}
      color={color}
      value={value}
      handleLegendClick={_handleLegendClick}
      handleLegendMouseOver={_handleLegendMouseOver}
      handleLegendMouseLeave={handleLegendMouseLeave}
      showNames={showNames}
      isHidden={isHidden}
      truncatedLongtail={truncatedLongtail}
      appearances={appearances}
      clusterData={clusterData}
    />
  );
}
