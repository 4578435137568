import clsx from 'clsx';

export interface MiddleTruncateProps {
  value: string;
  className?: string;
}

export function MiddleTruncate({ value, className }: MiddleTruncateProps) {
  if (value.length < 10) return <span className="truncate">{value}</span>;

  const sliceIndex = value.length - 5;
  const start = value.slice(0, sliceIndex);
  const end = value.slice(sliceIndex);

  return (
    <div className={clsx(className, 'w-full gap-0 flex flex-row items-start')}>
      <span className="truncate">{start}</span>
      <span className="">{end}</span>
    </div>
  );
}
