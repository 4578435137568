import { IconButton, Tooltip } from '@material-ui/core';
import { VisualizationFilter } from '../core/types/filters';
import { Reset } from '../ui/icons';
import { FILTER_OPERATOR_INVERSION_MAP } from './helpers';
import { useCallback } from 'react';

type FilterFormPopupProps = {
  className?: string;
  currentOperator: VisualizationFilter['operator'];
  setOperator: (operator: VisualizationFilter['operator']) => void;
};

export function InvertFilterOperator({
  className,
  currentOperator,
  setOperator,
}: FilterFormPopupProps) {
  const invertOperator = useCallback(() => {
    const newOperator = FILTER_OPERATOR_INVERSION_MAP[currentOperator];
    setOperator(newOperator);
  }, [currentOperator, setOperator]);

  return (
    <Tooltip title="Invert Filter Operator">
      <IconButton className={className}>
        <Reset className={className} onClick={invertOperator} />
      </IconButton>
    </Tooltip>
  );
}
